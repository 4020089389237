import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

// Context
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

// API
import { getPlatformProduct, getPlatformProductNoBox } from "../api/product";
import {
  validateIfGamePurchased,
  postUserReview,
  getGameReviews,
} from "../api/user";

// Hooks
// import useDidMountEffect from "../hooks/useDidMountEffect";

// Custom components
import StarRating from "../components/StarRating/StarRating";

// Custom styles
import "../scss/pages/product.styles.scss";

// Utils
import formatMoney from "../utils/formatMoney";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// Assets
// import product from "../assets/img/product-individual.jpg";
import withBoxIcon from "../assets/img/with-box-icon.svg";
import noBoxIcon from "../assets/img/no-box-icon.svg";

import ReactGA from "react-ga4";

const reviews2 = [
  {
    id: 1,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 1,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 2,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 3,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 1,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 4,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 5,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
  {
    id: 6,
    author: "Carlos López",
    score: 5,
    reviewDate: "24/05/2022",
    review:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
  },
];

const initialValues = {
  userName: "",
  review: "",
  // rating: 5,
};

let schema = yup.object().shape({
  userName: yup.string().required("Este campo es requerido"),
  review: yup.string().required("Este campo es requerido"),
  // rating: yup
  //   .number()
  //   .required("Este campo es requerido")
  //   .lessThan(1, "Error en tu calificación"),
});
// ReactGA.send({ hitType: "pageview", page: window.location.pathname });

function Product() {
  const [noBox, setNoBox] = useState({
    ags: "Sin Stock",
    ags_south: "Sin Stock",
  });
  const [withBox, setWithBox] = useState(false);
  const [withBoxChecked, setWithBoxChecked] = useState(true);
  const [noBoxChecked, setNoBoxChecked] = useState(false);
  const [rating, setRating] = useState(5);
  const [hover, setHover] = useState(0);
  const [product, setProduct] = useState();
  const [reviews, setReviews] = useState([]);
  // const [checked, setChecked] = useState(false);
  const [productPrice, setProductPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingUI, setLoadingUI] = useState(false);

  const { currentUser } = useAuth();
  const { addProductToCart, setCartItems, cartItems, guestAddFuProductToCart } =
    useCart();

  let { id } = useParams();
  const history = useHistory();

  const alert = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setNoBoxChecked(false);
      setWithBoxChecked(false);
      const response = await getPlatformProduct(id);
      if (response !== null) {
        setProduct(response);
        setLoading(false);
        setProductPrice(response.detail.Sale);
        if (!response.stock) {
          setWithBoxChecked(false);
        }
      } else setProduct([]);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getPlatformProductNoBox(id);
      if (response !== null) {
        setNoBox(response);
        // setLoading(false);
      } else setNoBox({});
    })();
  }, [id]);

  useEffect(() => {
    setWithBox(product?.stock);
    setWithBoxChecked(product?.stock);
    // console.log(withBox);
    // setNoBox(0);
  }, [product]);

  useEffect(() => {
    // setProductPrice(product?.detail?.Sale);
    // if (withBoxChecked) {
    //   setProductPrice(product?.detail?.Sale);
    // }
    if (noBoxChecked) {
      setProductPrice(noBox?.detail?.Sale);
      setWithBoxChecked(false);
    } else {
      setProductPrice(product?.detail?.Sale);
    }
    // setNoBox(0);
  }, [noBox, noBoxChecked, product]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getGameReviews(id);
      if (response !== null) {
        setReviews(response);
        // setLoading(false);
      } else setReviews([]);
    })();
  }, [id]);

  // useEffect(() => {
  //   addProductToCart(currentUser?.localId, cartItems);
  // }, [cartItems]);

  // useDidMountEffect(() => {
  //   // react please run me if 'key' changes, but not on initial render
  //   addProductToCart(currentUser?.localId, cartItems);
  // }, [loading]);

  // console.log(cartItems);
  if (loading) {
    return (
      <div className="individual-product__loading">
        <h4 className="text-center">Cargando producto...</h4>
      </div>
    );
  }
  return (
    <>
      <div className={`overlay ${loadingUI && "show"}`}></div>
      <div className="individual-product__wrapper">
        <Container fluid>
          <Row>
            <Col xs="12" lg="6">
              <div className="individual-product__media">
                <img
                  src={`https://gamerscode.mx/img/products/${id}.jpg`}
                  alt={product?.detail.Name}
                />
              </div>
            </Col>
            <Col xs="12" lg="6">
              <div className="individual-product__summary">
                <h6 className="individual-product__summary__category">
                  {product?.detail.Platform}
                </h6>
                <h1 className="individual-product__summary__title">
                  {product?.detail.Name}
                </h1>
                {product?.detail.Comments === "Accessory" &&
                product?.stock !== false &&
                product?.detail.Sale > 0 ? (
                  <h5 className="individual-product__summary__price">
                    {formatMoney(product?.detail.Sale)}
                    {/* <span>{`/${withBoxChecked ? "Con caja" : "Sin caja"}`}</span> */}
                  </h5>
                ) : (
                  productPrice > 0 && (
                    <>
                      {product?.detail.hasDiscount > 1 && (
                        <p className="individual-product__summary__discount">
                          Descuento sólo en línea
                        </p>
                      )}
                      <h5
                        className={`individual-product__summary__price ${
                          product.detail.hasDiscount > 1
                            ? `individual-product__summary__price__withDiscount`
                            : ""
                        }`}
                      >
                        {product.detail.hasDiscount > 1 ? (
                          <div className="d-flex flex-column">
                            {/* <h6>Sólo en línea</h6> */}
                            <div className="d-flex flex-row align-items-center">
                              <p>
                                {noBoxChecked
                                  ? formatMoney(noBox?.detail.originalPrice)
                                  : formatMoney(product?.detail.originalPrice)}
                              </p>
                              <span>{product?.detail.hasDiscount}% OFF</span>
                            </div>
                            <p>{formatMoney(productPrice)}</p>
                          </div>
                        ) : (
                          <p>{formatMoney(productPrice)}</p>
                        )}
                        {/* <span>{`/${withBoxChecked ? "Con caja" : "Sin caja"}`}</span> */}
                      </h5>
                      {/* {product?.detail.hasDiscount > 1 && (
                        <span className="individual-product__summary__discount__description">
                          Descuento aplicable sólo en compras en línea.
                        </span>
                      )} */}
                    </>
                  )
                )}
                {product?.detail.Comments === "Accessory" &&
                  !product?.stock && (
                    <h5
                      className="mt-2"
                      style={{
                        color: "#696c8a",
                        borderRadius: "10px",
                        border: "1px solid #696c8a",
                        width: "max-content",
                        padding: "7px 10px",
                        marginBottom: "1rem",
                      }}
                    >
                      No hay stock
                    </h5>
                  )}
                {product?.detail.Comments !== "Accessory" && (
                  <div className="individual-product__summary__condition">
                    <h6 className="mb-3">Condiciones disponibles:</h6>
                    <div className="d-flex">
                      <div>
                        <input
                          type="checkbox"
                          name="wihBox"
                          id="producWithBox"
                          // defaultChecked={true}
                          checked={withBoxChecked}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setWithBoxChecked(!withBoxChecked);
                              setNoBoxChecked(false);
                            } else {
                              setWithBoxChecked(!withBoxChecked);
                            }
                          }}
                          disabled={withBox === false}
                        />
                        <label htmlFor="producWithBox">
                          <span className="withBoxIcon"></span>
                          <span>Con caja</span>
                        </label>
                        {withBox ? <p>En stock</p> : <p>Sin stock</p>}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="noBox"
                          id="producNoBox"
                          checked={noBoxChecked}
                          value={noBoxChecked || ""}
                          onChange={(e) => {
                            if (withBox) {
                              // console.log("debio desactivar");
                              setWithBoxChecked(false);
                              setNoBoxChecked(!noBoxChecked);
                            } else {
                              setNoBoxChecked(!noBoxChecked);
                            }
                          }}
                          disabled={noBox.stock === false}
                        />
                        <label htmlFor="producNoBox">
                          <span className="withNoBoxIcon"></span>
                          <span>Sin caja</span>
                        </label>
                        {noBox?.stock ? <p>En stock</p> : <p>Sin stock</p>}
                      </div>
                    </div>
                  </div>
                )}
                <div className="individual-product__summary__description">
                  {product?.detail.Platform === "N64" ||
                  product?.detail.Platform === "GameCube" ||
                  product?.detail.Platform === "SNES" ||
                  product?.detail.Platform === "NES" ||
                  product?.detail.Platform === "Sega Genesis" ||
                  product?.detail.Platform === "Amiibos" ? (
                    <p className="mt-3 mt-lg-0">
                      Estos artículos están disponibles para compra solo en la
                      sección retro, ya que el precio depende de la condición
                      del artículo, cada artículo tiene una fotografía
                      individual, por favor da click en el enlace para ir a la
                      sección retro{" "}
                      <Link
                        to={`/retro/${product?.detail.Platform.toLowerCase()}`}
                      >{`www.gamerscode.mx/retro/${product?.detail.Platform.toLowerCase()}`}</Link>
                    </p>
                  ) : (
                    <p>{product?.detail.Description}</p>
                  )}
                  {product?.detail.Platform === "N64" ||
                  product?.detail.Platform === "GameCube" ||
                  product?.detail.Platform === "SNES" ||
                  product?.detail.Platform === "NES" ||
                  product?.detail.Platform === "Sega Genesis" ||
                  product?.detail.Platform === "Amiibos" ? (
                    <></>
                  ) : noBoxChecked === true || withBoxChecked === true ? (
                    <div>
                      <button
                        className="d-flex d-lg-none me-lg-3"
                        onClick={async () => {
                          // setCartItems([
                          //   ...cartItems,
                          //   {
                          //     ...product.detail,
                          //     ID_Info: id,
                          //     Quantity: 1,
                          //   },
                          // ]);
                          // setTimeout(() => {
                          // }, 300);
                          if (currentUser.localId) {
                            if (cartItems?.length === 0) {
                              // console.log("creó");
                              setLoadingUI(true);
                              const response = await addProductToCart(
                                currentUser.localId,
                                [
                                  {
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]
                              );
                              if (response.status === 200) {
                                setCartItems([
                                  {
                                    // ...product.detail,
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]);
                                ReactGA.event({
                                  category: "User",
                                  action: "Added to bag as registered user",
                                });
                                alert.success(
                                  "Producto añadido a tu bolsa de compra"
                                );
                                setLoadingUI(false);
                              } else {
                                alert.error(
                                  "Error al agregar producto a la bolsa."
                                );
                                setLoadingUI(false);
                              }
                            } else {
                              // console.log("agregó");
                              setLoadingUI(true);
                              for (let i = 0; i < cartItems.length; i++) {
                                // const element = cartItems[i];
                                if (cartItems[i].ID_Info === id) {
                                  alert.error(
                                    "Solo puedes comprar una pieza por producto."
                                  );
                                  setLoadingUI(false);
                                  return;
                                }
                              }
                              const response = await addProductToCart(
                                currentUser.localId,
                                [
                                  ...cartItems,
                                  {
                                    // ...product.detail,
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]
                              );
                              if (response.status === 200) {
                                setCartItems([
                                  ...cartItems,
                                  {
                                    // ...product.detail,
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]);
                                alert.success(
                                  "Producto añadido a tu bolsa de compra"
                                );
                                ReactGA.event({
                                  category: "User",
                                  action: "Added to bag as registered user",
                                });
                                setLoadingUI(false);
                              } else {
                                alert.error(
                                  "Error al agregar producto a la bolsa."
                                );
                                setLoadingUI(false);
                              }
                            }
                          } else if (currentUser.guest === true) {
                            if (cartItems?.length === 0) {
                              // console.log("creó");
                              guestAddFuProductToCart(product.detail, id);
                              ReactGA.event({
                                category: "User",
                                action: "Added to bag as guest",
                              });
                              // guestAddFuProductToCart([product.detail]);
                            } else {
                              // console.log("agregó");
                              // console.log(cartItems);
                              // console.log(cartItems.filter());
                              for (let i = 0; i < cartItems.length; i++) {
                                // const element = cartItems[i];
                                if (cartItems[i].ID_Info === id) {
                                  alert.error(
                                    "Solo puedes comprar una pieza por producto."
                                  );
                                  return;
                                }
                              }
                              // console.log("no hay pieza en el carrito");
                              guestAddFuProductToCart([
                                ...cartItems,
                                {
                                  // ...product.detail,
                                  ID_Info: id,
                                  Name: product.detail.Name,
                                  Platform: product.detail.Platform,
                                  Purchase: `${product.detail.Purchase}`,
                                  Exchange: `${product.detail.Exchange}`,
                                  Sale: `${
                                    noBoxChecked
                                      ? noBox?.detail?.Sale
                                      : product.detail.Sale
                                  }`,
                                  Quantity: "1",
                                },
                              ]);
                              alert.success(
                                "Producto añadido a la bolsa de compra"
                              );
                              ReactGA.event({
                                category: "User",
                                action: "Added to bag as guest",
                              });
                              // if (
                              //   cartItems.includes(
                              //     {
                              //       ID_Info: id,
                              //       Name: product.detail.Name,
                              //       Platform: product.detail.Platform,
                              //       Purchase: `${product.detail.Purchase}`,
                              //       Exchange: `${product.detail.Exchange}`,
                              //       Sale: `${product.detail.Sale}`,
                              //       Quantity: "1",
                              //     },
                              //     0
                              //   )
                              // ) {

                              // }
                              //  else {
                              //   guestAddFuProductToCart([
                              //     ...cartItems,
                              //     {
                              //       // ...product.detail,
                              //       ID_Info: id,
                              //       Name: product.detail.Name,
                              //       Platform: product.detail.Platform,
                              //       Purchase: `${product.detail.Purchase}`,
                              //       Exchange: `${product.detail.Exchange}`,
                              //       Sale: `${product.detail.Sale}`,
                              //       Quantity: "1",
                              //     },
                              //   ]);
                              // }
                            }
                          } else {
                            // console.log("first time guest añadir");
                            guestAddFuProductToCart(product.detail, id);
                            history.push("/guest");
                          }
                        }}
                        disabled={withBox && noBox?.stock === 0}
                      >
                        {loadingUI
                          ? "Añadiendo a la bolsa..."
                          : "Añadir a la bolsa"}
                      </button>
                      <button
                        className={`d-block d-lg-none mx-auto btn--red ${
                          loadingUI && `d-lg-none`
                        }`}
                        onClick={async () => {
                          if (currentUser.localId) {
                            if (cartItems?.length === 0) {
                              setLoadingUI(true);
                              const response = await addProductToCart(
                                currentUser.localId,
                                [
                                  {
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]
                              );
                              if (response.status === 200) {
                                alert.success(
                                  "Producto añadido a tu bolsa de compra"
                                );
                                history.push("/cart");
                                ReactGA.event({
                                  category: "User",
                                  action: "Buy as registered user",
                                });
                                setLoadingUI(false);
                              } else {
                                alert.error(
                                  "Error al agregar producto a la bolsa."
                                );
                                setLoadingUI(false);
                              }
                            } else {
                              for (let i = 0; i < cartItems.length; i++) {
                                setLoadingUI(true);
                                if (cartItems[i].ID_Info === id) {
                                  alert.error(
                                    "Solo puedes comprar una pieza por producto."
                                  );
                                  setLoadingUI(false);
                                  return;
                                }
                              }
                              const response = await addProductToCart(
                                currentUser.localId,
                                [
                                  ...cartItems,
                                  {
                                    // ...product.detail,
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]
                              );
                              if (response.status === 200) {
                                setCartItems([
                                  ...cartItems,
                                  {
                                    // ...product.detail,
                                    ID_Info: id,
                                    Name: product.detail.Name,
                                    Platform: product.detail.Platform,
                                    Purchase: `${product.detail.Purchase}`,
                                    Exchange: `${product.detail.Exchange}`,
                                    Sale: `${
                                      noBoxChecked
                                        ? noBox?.detail?.Sale
                                        : product.detail.Sale
                                    }`,
                                    Quantity: "1",
                                    typeOfBox: withBoxChecked
                                      ? "Con caja"
                                      : "Sin caja",
                                  },
                                ]);
                                alert.success(
                                  "Producto añadido a tu bolsa de compra"
                                );
                                ReactGA.event({
                                  category: "User",
                                  action: "Buy as registered user",
                                });
                                history.push("/cart");
                                setLoadingUI(false);
                              } else {
                                alert.error(
                                  "Error al agregar producto a la bolsa."
                                );
                                setLoadingUI(false);
                              }
                            }
                          } else if (currentUser.guest === true) {
                            if (cartItems?.length === 0) {
                              guestAddFuProductToCart(product.detail, id);
                              ReactGA.event({
                                category: "User",
                                action: "Buy as guest",
                              });
                              history.push("/cart");
                            } else {
                              for (let i = 0; i < cartItems.length; i++) {
                                // const element = cartItems[i];
                                if (cartItems[i].ID_Info === id) {
                                  alert.error(
                                    "Solo puedes comprar una pieza por producto."
                                  );
                                  return;
                                }
                              }
                              guestAddFuProductToCart([
                                ...cartItems,
                                {
                                  // ...product.detail,
                                  ID_Info: id,
                                  Name: product.detail.Name,
                                  Platform: product.detail.Platform,
                                  Purchase: `${product.detail.Purchase}`,
                                  Exchange: `${product.detail.Exchange}`,
                                  Sale: `${
                                    noBoxChecked
                                      ? noBox?.detail?.Sale
                                      : product.detail.Sale
                                  }`,
                                  Quantity: "1",
                                  typeOfBox: withBoxChecked
                                    ? "Con caja"
                                    : "Sin caja",
                                },
                              ]);
                              alert.success(
                                "Producto añadido a la bolsa de compra"
                              );
                              ReactGA.event({
                                category: "User",
                                action: "Buy as guest",
                              });
                              history.push("/cart");
                            }
                          } else {
                            // console.log("first time gues");
                            guestAddFuProductToCart(product.detail, id);
                            history.push("/guest");
                          }
                        }}
                        disabled={withBox && noBox?.stock === 0}
                      >
                        Comprar
                      </button>
                    </div>
                  ) : (
                    <button
                      disabled={
                        (noBoxChecked ? false : true) &&
                        (withBoxChecked ? false : true)
                      }
                      className="d-block d-lg-none"
                    >
                      Escoge una condición
                    </button>
                  )}
                </div>
                {product?.stock !== false && (
                  <div className="individual-product__summary__stock">
                    <Accordion flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Ver stock disponible
                        </Accordion.Header>
                        <Accordion.Body>
                          {product?.detail.Comments === "Accessory" ? (
                            <table>
                              <thead>
                                <tr>
                                  <th>Sucursal</th>
                                  <th>En Stock</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Aguascalientes</td>
                                  <td>{product?.ags}</td>
                                </tr>
                                <tr>
                                  <td>Aguascalientes Sur</td>
                                  <td>{product?.ags}</td>
                                </tr>
                                <tr>
                                  <td>León</td>
                                  <td>{product?.leon}</td>
                                </tr>
                                <tr>
                                  <td>Zacatecas</td>
                                  <td>{product?.zcs}</td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <>
                              <h6>
                                <img
                                  src={withBoxIcon}
                                  alt={product?.detail.Name}
                                />
                                Con caja:
                              </h6>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sucursal</th>
                                    <th>En Stock</th>
                                  </tr>
                                </thead>
                                {product?.stock ? (
                                  <tbody>
                                    <tr>
                                      <td>Aguascalientes</td>
                                      <td
                                        className={
                                          product?.ags === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {product?.ags}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Aguascalientes Villasunción</td>
                                      <td
                                        className={
                                          product?.ags_south === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {product?.ags_south}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>León</td>
                                      <td
                                        className={
                                          product?.leon === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {product?.leon}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Zacatecas</td>
                                      <td
                                        className={
                                          product?.zcs === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {product?.zcs}
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className="no-stock">No hay Stock</td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                              <h6 className="mt-3">
                                <img
                                  src={noBoxIcon}
                                  alt={product?.detail.Name}
                                  className="me-1"
                                />
                                Sin caja:
                              </h6>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sucursal</th>
                                    <th>En Stock</th>
                                  </tr>
                                </thead>
                                {noBox.stock ? (
                                  <tbody>
                                    <tr>
                                      <td>Aguascalientes</td>
                                      <td
                                        className={
                                          noBox?.ags === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {noBox?.ags}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Aguascalientes Villasunción</td>
                                      <td
                                        className={
                                          noBox?.ags_south === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {noBox?.ags_south}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>León</td>
                                      <td
                                        className={
                                          noBox?.leon === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {noBox?.leon}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Zacatecas</td>
                                      <td
                                        className={
                                          noBox?.zcs === "En Stock"
                                            ? "yes-stock"
                                            : "no-stock"
                                        }
                                      >
                                        {noBox?.zcs}
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className="no-stock no-stock--alone">
                                        No hay Stock
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <section className="individual-product__reviews">
          <Container>
            <div className="individual-product__reviews__title">
              <div></div>
              <h3>Comentarios</h3>
              <div></div>
            </div>
            <Row>
              {reviews.length > 0 ? (
                reviews.map((review, key) => (
                  <Col xs="12" lg="6" key={key}>
                    <div className="individual-product__reviews__review">
                      <div className="individual-product__reviews__review__header">
                        <div className="individual-product__reviews__review__header__info">
                          <h6>{review.userName}</h6>
                          {[...Array(parseInt(review?.rating))].map(
                            (star, index) => {
                              index += 1;
                              return (
                                <span className="star" key={index}>
                                  &#9733;
                                </span>
                              );
                            }
                          )}
                        </div>
                        <p>{review.reviewDate}</p>
                      </div>
                      <div className="individual-product__reviews__review__content">
                        <p>{review.review}</p>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <p className="pt-3 ps-3 m-0">
                  Este producto no tiene comentarios.
                </p>
              )}
            </Row>
          </Container>
          {currentUser.localId ? (
            <div className="individual-product__reviews__form">
              <Container>
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={(values, { resetForm }) => {
                    values.rating = rating;
                    values.producID = id;
                    values.userID = currentUser.localId;
                    validateIfGamePurchased(currentUser.localId, id).then(
                      (data) => {
                        data.json().then((json) => {
                          if (json[0] === 0) {
                            alert.error(
                              "Sólo puedes hacer comentarios de juegos que hayas adquirido."
                            );
                          } else if (json[0] === 1) {
                            postUserReview(
                              currentUser.localId,
                              values.userName,
                              id,
                              rating,
                              values.review
                            )
                              .then((data) => {
                                data
                                  .json()
                                  .then((json) => {
                                    if (json[0] === 0) {
                                      alert.error(
                                        "Hubo un error en tu solicitud."
                                      );
                                    } else if (json[0] === 1) {
                                      alert.success(
                                        "Tu reseña ha sido enviada para su revisión, vuelve pronto para verificar que se haya aprobado."
                                      );
                                    } else {
                                      alert.error(
                                        "Hubo un error en tu solicitud."
                                      );
                                    }
                                  })
                                  .catch((error) => {
                                    alert.error(
                                      "Hubo un error en tu solicitud."
                                    );
                                    console.error("Error:", error);
                                  });
                              })
                              .catch((error) => {
                                alert.error("Hubo un error en tu solicitud.");
                                console.error("Error:", error);
                              });
                          } else {
                            alert.error("Hubo un error en tu solicitud.");
                          }
                        });
                      }
                    );
                    // console.log(values);
                    resetForm(initialValues);
                    setRating(5);
                    setHover(5);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h4>Deja tu comentario del producto</h4>
                      <Row>
                        <Form.Group className="mb-3" as={Col} xs="12" lg="6">
                          <Form.Label>Tu nombre:</Form.Label>
                          <Form.Control
                            type="text"
                            name="userName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userName}
                            isInvalid={!!errors.userName && touched.userName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.userName || touched.userName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" as={Col} xs="6" lg="6">
                          <Form.Label>Calificación:</Form.Label>
                          <StarRating
                            rating={rating}
                            setRating={setRating}
                            hover={hover}
                            setHover={setHover}
                            value={rating}
                            // onChange={handleChange}
                            // isInvalid={!!errors.rating && touched.rating}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.rating || touched.rating}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                          as={Col}
                        >
                          <Form.Label>Comentario:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={8}
                            name="review"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.review}
                            isInvalid={!!errors.review && touched.review}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.review || touched.review}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <button type="submit" id="submitButton">
                        Enviar
                      </button>
                    </Form>
                  )}
                </Formik>
              </Container>
            </div>
          ) : (
            <div className="individual-product__reviews__form">
              <Container>
                <div className="individual-product__reviews__form__container">
                  <p className="text-center">
                    Tienes que <Link to="/login">iniciar sesión</Link> o{" "}
                    <Link to="/register">tener una cuenta</Link> para publicar
                    un comentario.
                  </p>
                </div>
              </Container>
            </div>
          )}
        </section>
        {product?.detail.Platform === "N64" ||
        product?.detail.Platform === "GameCube" ||
        product?.detail.Platform === "SNES" ||
        product?.detail.Platform === "NES" ||
        product?.detail.Platform === "Sega Genesis" ||
        product?.detail.Platform === "Amiibos" ||
        productPrice === 0 ? (
          <></>
        ) : (
          <div className="individual-product__purchase-nav">
            <div className="individual-product__purchase-nav__info">
              <h2>{product?.detail.Name}</h2>
              {productPrice > 0 && <h4>{formatMoney(productPrice)}</h4>}
            </div>
            <div className="individual-product__purchase-nav__cta">
              {/* <button>Añadir a la bolsa</button>
                <button to="/cart">Comprar</button> */}
              {noBoxChecked === true ||
              withBoxChecked === true ||
              noBox?.stock === 0 ? (
                <div className="d-flex">
                  <button
                    className="d-none d-lg-flex me-lg-3"
                    onClick={async () => {
                      // setCartItems([
                      //   ...cartItems,
                      //   {
                      //     ...product.detail,
                      //     ID_Info: id,
                      //     Quantity: 1,
                      //   },
                      // ]);
                      // setTimeout(() => {
                      // }, 300);
                      if (currentUser.localId) {
                        if (cartItems?.length === 0) {
                          // console.log("creó");
                          setLoadingUI(true);
                          const response = await addProductToCart(
                            currentUser.localId,
                            [
                              {
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox.detail.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]
                          );
                          if (response.status === 200) {
                            setCartItems([
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]);
                            alert.success(
                              "Producto añadido a tu bolsa de compra"
                            );
                            ReactGA.event({
                              category: "User",
                              action: "Added to bag as registered user",
                            });
                            setLoadingUI(false);
                          } else {
                            alert.error(
                              "Error al agregar producto a la bolsa."
                            );
                            setLoadingUI(false);
                          }
                        } else {
                          // console.log("agregó");
                          setLoadingUI(true);
                          for (let i = 0; i < cartItems.length; i++) {
                            // const element = cartItems[i];
                            if (cartItems[i].ID_Info === id) {
                              alert.error(
                                "Solo puedes comprar una pieza por producto."
                              );
                              setLoadingUI(false);
                              return;
                            }
                          }
                          const response = await addProductToCart(
                            currentUser.localId,
                            [
                              ...cartItems,
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]
                          );
                          if (response.status === 200) {
                            setCartItems([
                              ...cartItems,
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]);
                            alert.success(
                              "Producto añadido a tu bolsa de compra"
                            );
                            setLoadingUI(false);
                            ReactGA.event({
                              category: "User",
                              action: "Added to bag as registered user",
                            });
                          } else {
                            alert.error(
                              "Error al agregar producto a la bolsa."
                            );
                            setLoadingUI(false);
                          }
                        }
                      } else if (currentUser.guest === true) {
                        if (cartItems?.length === 0) {
                          // console.log("creó");
                          guestAddFuProductToCart(
                            {
                              // ...product.detail,
                              ID_Info: id,
                              Name: product.detail.Name,
                              Platform: product.detail.Platform,
                              Purchase: `${product.detail.Purchase}`,
                              Exchange: `${product.detail.Exchange}`,
                              Sale: `${
                                noBoxChecked
                                  ? noBox.detail.Sale
                                  : product.detail.Sale
                              }`,
                              Quantity: "1",
                            },
                            id
                          );
                          ReactGA.event({
                            category: "User",
                            action: "Added to bag as guest",
                          });
                          // guestAddFuProductToCart([product.detail]);
                        } else {
                          // console.log("agregó");
                          // console.log(cartItems);
                          // console.log(cartItems.filter());
                          for (let i = 0; i < cartItems.length; i++) {
                            // const element = cartItems[i];
                            if (cartItems[i].ID_Info === id) {
                              alert.error(
                                "Solo puedes comprar una pieza por producto."
                              );
                              return;
                            }
                          }
                          // console.log("no hay pieza en el carrito");
                          guestAddFuProductToCart([
                            ...cartItems,
                            {
                              // ...product.detail,
                              ID_Info: id,
                              Name: product.detail.Name,
                              Platform: product.detail.Platform,
                              Purchase: `${product.detail.Purchase}`,
                              Exchange: `${product.detail.Exchange}`,
                              Sale: `${
                                noBoxChecked
                                  ? noBox.detail.Sale
                                  : product.detail.Sale
                              }`,
                              Quantity: "1",
                            },
                          ]);
                          alert.success(
                            "Producto añadido a la bolsa de compra"
                          );
                          ReactGA.event({
                            category: "User",
                            action: "Added to bag as guest",
                          });
                          // if (
                          //   cartItems.includes(
                          //     {
                          //       ID_Info: id,
                          //       Name: product.detail.Name,
                          //       Platform: product.detail.Platform,
                          //       Purchase: `${product.detail.Purchase}`,
                          //       Exchange: `${product.detail.Exchange}`,
                          //        Sale: `${noBoxChecked ? noBox?.detail?.Sale : product.detail.Sale}`,
                          //       Quantity: "1",
                          //     },
                          //     0
                          //   )
                          // ) {

                          // }
                          //  else {
                          //   guestAddFuProductToCart([
                          //     ...cartItems,
                          //     {
                          //       // ...product.detail,
                          //       ID_Info: id,
                          //       Name: product.detail.Name,
                          //       Platform: product.detail.Platform,
                          //       Purchase: `${product.detail.Purchase}`,
                          //       Exchange: `${product.detail.Exchange}`,
                          //        Sale: `${noBoxChecked ? noBox?.detail?.Sale : product.detail.Sale}`,
                          //       Quantity: "1",
                          //     },
                          //   ]);
                          // }
                        }
                      } else {
                        // console.log("first time guest añadir");
                        guestAddFuProductToCart(
                          {
                            // ...product.detail,
                            ID_Info: id,
                            Name: product.detail.Name,
                            Platform: product.detail.Platform,
                            Purchase: `${product.detail.Purchase}`,
                            Exchange: `${product.detail.Exchange}`,
                            Sale: `${
                              noBoxChecked
                                ? noBox.detail.Sale
                                : product.detail.Sale
                            }`,
                            Quantity: "1",
                          },
                          id
                        );
                        history.push("/guest");
                      }
                    }}
                    disabled={withBox && noBox?.stock === 0}
                  >
                    {loadingUI
                      ? "Añadiendo a la bolsa..."
                      : "Añadir a la bolsa"}
                  </button>
                  <button
                    className={`d-none d-lg-block mx-auto btn--red ${
                      loadingUI && `d-lg-none`
                    }`}
                    onClick={async () => {
                      if (currentUser.localId) {
                        if (cartItems?.length === 0) {
                          setLoadingUI(true);
                          const response = await addProductToCart(
                            currentUser.localId,
                            [
                              {
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]
                          );
                          if (response.status === 200) {
                            setCartItems([
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]);
                            alert.success(
                              "Producto añadido a tu bolsa de compra"
                            );
                            ReactGA.event({
                              category: "User",
                              action: "Buy as registered user",
                            });
                            history.push("/cart");
                            setLoadingUI(false);
                          } else {
                            alert.error(
                              "Error al agregar producto a la bolsa."
                            );
                            setLoadingUI(false);
                          }
                        } else {
                          for (let i = 0; i < cartItems.length; i++) {
                            setLoadingUI(true);
                            if (cartItems[i].ID_Info === id) {
                              alert.error(
                                "Solo puedes comprar una pieza por producto."
                              );
                              setLoadingUI(false);
                              return;
                            }
                          }
                          const response = await addProductToCart(
                            currentUser.localId,
                            [
                              ...cartItems,
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]
                          );
                          if (response.status === 200) {
                            setCartItems([
                              ...cartItems,
                              {
                                // ...product.detail,
                                ID_Info: id,
                                Name: product.detail.Name,
                                Platform: product.detail.Platform,
                                Purchase: `${product.detail.Purchase}`,
                                Exchange: `${product.detail.Exchange}`,
                                Sale: `${
                                  noBoxChecked
                                    ? noBox?.detail?.Sale
                                    : product.detail.Sale
                                }`,
                                Quantity: "1",
                                typeOfBox: withBoxChecked
                                  ? "Con caja"
                                  : "Sin caja",
                              },
                            ]);
                            alert.success(
                              "Producto añadido a tu bolsa de compra"
                            );
                            ReactGA.event({
                              category: "User",
                              action: "Buy as registered user",
                            });
                            history.push("/cart");
                            setLoadingUI(false);
                          } else {
                            alert.error(
                              "Error al agregar producto a la bolsa."
                            );
                            setLoadingUI(false);
                          }
                        }
                      } else if (currentUser.guest === true) {
                        if (cartItems?.length === 0) {
                          guestAddFuProductToCart(
                            {
                              // ...product.detail,
                              ID_Info: id,
                              Name: product.detail.Name,
                              Platform: product.detail.Platform,
                              Purchase: `${product.detail.Purchase}`,
                              Exchange: `${product.detail.Exchange}`,
                              Sale: `${
                                noBoxChecked
                                  ? noBox?.detail?.Sale
                                  : product.detail.Sale
                              }`,
                              Quantity: "1",
                            },
                            id
                          );
                          history.push("/cart");
                          ReactGA.event({
                            category: "User",
                            action: "Buy as guest",
                          });
                        } else {
                          for (let i = 0; i < cartItems.length; i++) {
                            // const element = cartItems[i];
                            if (cartItems[i].ID_Info === id) {
                              alert.error(
                                "Solo puedes comprar una pieza por producto."
                              );
                              return;
                            }
                          }
                          guestAddFuProductToCart([
                            ...cartItems,
                            {
                              // ...product.detail,
                              ID_Info: id,
                              Name: product.detail.Name,
                              Platform: product.detail.Platform,
                              Purchase: `${product.detail.Purchase}`,
                              Exchange: `${product.detail.Exchange}`,
                              Sale: `${
                                noBoxChecked
                                  ? noBox?.detail?.Sale
                                  : product.detail.Sale
                              }`,
                              Quantity: "1",
                            },
                          ]);
                          alert.success(
                            "Producto añadido a la bolsa de compra"
                          );
                          ReactGA.event({
                            category: "User",
                            action: "Buy as guest",
                          });
                          history.push("/cart");
                        }
                      } else {
                        // console.log("first time gues");
                        guestAddFuProductToCart(
                          {
                            // ...product.detail,
                            ID_Info: id,
                            Name: product.detail.Name,
                            Platform: product.detail.Platform,
                            Purchase: `${product.detail.Purchase}`,
                            Exchange: `${product.detail.Exchange}`,
                            Sale: `${
                              noBoxChecked
                                ? noBox?.detail?.Sale
                                : product.detail.Sale
                            }`,
                            Quantity: "1",
                          },
                          id
                        );
                        history.push("/guest");
                      }
                    }}
                    disabled={withBox && noBox?.stock === 0}
                  >
                    Comprar
                  </button>
                </div>
              ) : (
                // <button
                //   disabled={
                //     (noBoxChecked ? false : true) &&
                //     (withBoxChecked ? false : true)
                //   }
                //   className="d-none d-lg-block"
                // >
                //   Escoge una condición para saber el precio
                // </button>
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Product;
