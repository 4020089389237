import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";

// Custom components
import CustomPagination from "../components/Pagination/CustomPagination";

// Custom styles
import "../scss/pages/catalogue.styles.scss";

// API
import { getPlatformProducts } from "../api/product";

// Utils
import formatMoney from "../utils/formatMoney";

// Assets
// import filtersIcon from "../assets/img/filters-icon.svg";
import filtersIconBlack from "../assets/img/filter-icon--black.svg";
// import product from "../assets/img/product-1.jpg";

function Catalogue() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kindSpanish, setKindSpanish] = useState("");

  let { kind, platform } = useParams();
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const kindFormatted = kind.charAt(0).toUpperCase() + kind.slice(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(40);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = products?.slice(indexOfFirstPost, indexOfLastPost);
  // console.log(currentPosts);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // console.log(products);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setIsSelected("");
      setIsPriceSelected("");
      setIsInStockSelected("");
      filterSelect("ORDER", null, "");
      const response = await getPlatformProducts(kindFormatted, platform);
      setProducts(response.app_data);
      setOriginalProducts(response.app_data);
      setCurrentPage(1);
      setLoading(false);
    })();
  }, [kind, platform]);

  useEffect(() => {
    if (kind === "accessory") {
      setKindSpanish("Accesorios");
    } else {
      setKindSpanish("Juegos");
    }
    if (
      platform === "N64" ||
      platform === "SNES" ||
      platform === "Sega Genesis"
    ) {
      setKindSpanish("Juegos, Consolas y Accesorios");
    }
  }, [kind, platform]);

  // --------- Filters implementation ---------
  const initFilter = {
    order: "",
    priceOrder: "",
    condition: "",
  };

  const [filter, setFilter] = useState(initFilter);
  const [isSelected, setIsSelected] = useState(null);
  const [isPriceSelected, setIsPriceSelected] = useState(null);
  const [isInStockSelected, setIsInStockSelected] = useState(null);

  const filterSelect = (type, checked, item) => {
    if (checked) {
      switch (type) {
        case "ORDER":
          setFilter({ ...filter, order: item });
          break;
        case "PRICEORDER":
          setFilter({ ...filter, priceOrder: item });
          break;
        case "CONDITION":
          setFilter({ ...filter, condition: item });
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case "ORDER":
          setFilter(initFilter);
          break;
        case "PRICEORDER":
          setFilter(initFilter);
          break;
        case "CONDITION":
          setFilter(initFilter);
          break;

        default:
          break;
      }
    }
  };

  const updateProducts = () => {
    let temp = products;
    // console.log(temp);
    if (isSelected === "desc") {
      // const globalData = temp.map((data) => data);
      // console.log(products.length);
      // console.log(temp.sort((a, b) => b.Name - a.Name));
      temp = temp.sort((a, b) =>
        b.Name > a.Name ? 1 : a.Name > b.Name ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isSelected === "asc") {
      // const globalData = temp.map((data) => data);
      // console.log(products.length);
      // console.log(temp.sort((a, b) => b.Name - a.Name));
      temp = temp.sort((a, b) =>
        a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isPriceSelected === "priceAsc") {
      temp = temp.sort((a, b) =>
        b.Sale > a.Sale ? 1 : a.Sale > b.Sale ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isPriceSelected === "priceDesc") {
      temp = temp.sort((a, b) =>
        a.Sale > b.Sale ? 1 : b.Sale > a.Sale ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    // if (!isSelected || !isPriceSelected) {
    //   temp = temp?.sort((a, b) =>
    //     a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
    //   );
    // }
    // if (isSelected === "desc" && isPriceSelected === "priceAsc") {
    //   console.log("both");
    //   // temp = temp
    //   //   .sort((a, b) => (b.Sale > a.Sale ? 1 : a.Sale > b.Sale ? -1 : 0))
    //   //   .sort((a, b) => (b.Name > a.Name ? 1 : a.Name > b.Name ? -1 : 0));

    //   temp = temp.sort(
    //     (a, b) => b.Sale - a.Sale || b.Name.localeCompare(a.Name)
    //   );
    //   console.log(
    //     temp.sort((a, b) => b.Name.localeCompare(a.Name) || a.Sale - b.Sale)
    //   );
    // }
    // if (isSelected === "") {
    //   temp = temp?.sort((a, b) =>
    //     a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
    //   );
    // }
    // if (isPriceSelected === "") {
    //   temp = temp?.sort((a, b) =>
    //     a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
    //   );
    // }
    if (isInStockSelected === "inStock") {
      let filteredArray = temp;
      filteredArray = filteredArray.filter(
        (product) => product.Stock !== "Sin Inventario"
      );
      setCurrentPage(1);
      setProducts(filteredArray);
      handleClose();
    }
    if (isInStockSelected === "") {
      setProducts(originalProducts);
    }
    // if (isPriceSelected === "") {
    //   setProducts(originalProducts);
    // }
    // if (isSelected === "") {
    //   setProducts(originalProducts);
    // }
    // if (
    //   isSelected === "" ||
    //   isPriceSelected === ""
    //   // || isInStockSelected === ""
    // ) {
    //   temp = temp.sort((a, b) =>
    //     a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
    //   );
    //   setProducts(temp);
    //   handleClose();
    // }
  };

  useEffect(() => {
    updateProducts();
  }, [isSelected, isPriceSelected, isInStockSelected, filter]);

  // --------- END OF Filters implementation ---------

  return (
    <div className="catalogue__wrapper">
      {/* {console.log(filter)} */}
      <Container fluid>
        <Row>
          <Col xs="12" lg="12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div
                className={`catalogue__category ${
                  kind === "accessory" && "mt-3"
                }`}
              >
                <h5>{platform}</h5>
              </div>
              {kind !== "accessory" && (
                <div
                  className="catalogue__filters__cta--mobile"
                  onClick={toggleShow}
                >
                  <img src={filtersIconBlack} alt="Gamerscode" />
                  <h6>Filtros</h6>
                </div>
              )}
            </div>
            <div className="catalogue__container">
              <h4>{kindSpanish}</h4>
              <p>
                Estos productos se encuentran en nuestras en tiendas físicas y
                venta en línea, si deseas una foto de la condición actual del
                producto puedes enviar un{" "}
                <a
                  href="https://wa.me/524773909521"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Whatsapp
                </a>
                . Todos los productos son probados antes de ser enviados.
              </p>

              {loading ? (
                <div className="loading__wrapper">
                  <h5>Cargando productos...</h5>
                </div>
              ) : products?.length > 0 ? (
                <>
                  <Row xs={2} md={2} lg={4} xl={5} xxl={6} className="g-3">
                    {currentPosts?.map((product, key) => (
                      <Col key={key}>
                        <Card
                          style={{ height: "100%" }}
                          className={`product__card ${
                            product.hasDiscount > 1 && `withDiscount`
                          }`}
                        >
                          {product.hasDiscount > 1 && (
                            <div className="product__card__discount">
                              <span>Descuento solo en línea</span>
                            </div>
                          )}
                          <Link to={`/product/${product.ID_Info}`}>
                            <Card.Img
                              variant="top"
                              src={`https://gamerscode.mx/img/products/${product.ID_Info}.jpg`}
                              className={
                                platform === "N64" || platform === "SNES"
                                  ? "n64"
                                  : ""
                              }
                            />
                            <Card.Body>
                              <div
                                className={`product__card__price  ${
                                  product.hasDiscount > 1 &&
                                  `product__card__price__withDiscount`
                                }`}
                              >
                                {product.hasDiscount > 1 ? (
                                  <div className="d-flex flex-column">
                                    {/* <h6>Sólo en línea</h6> */}
                                    <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                                      <p>
                                        {formatMoney(product.originalPrice)}
                                      </p>
                                      <span>{product.hasDiscount}% OFF</span>
                                    </div>
                                    <p>{formatMoney(product.Sale)}</p>
                                  </div>
                                ) : (
                                  <p>{formatMoney(product.Sale)}</p>
                                )}
                              </div>
                              {platform === "GameBoy Color" ||
                              platform === "GBA" ||
                              platform === "GameBoy" ||
                              platform === "NES" ||
                              platform === "SNES" ||
                              platform === "N64" ||
                              platform === "Sega Genesis" ? (
                                <span>precio sin caja</span>
                              ) : (
                                <span>precio con caja</span>
                              )}

                              <h5>{product.Name}</h5>
                              <Card.Text>
                                {platform === "GameBoy Color" ||
                                platform === "GBA" ||
                                platform === "GameBoy" ? (
                                  <></>
                                ) : (
                                  `${product.Stock}`
                                )}
                              </Card.Text>
                            </Card.Body>
                          </Link>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col xs="12" xl="12" className="d-flex align-items-end">
                      <CustomPagination
                        postsPerPage={postsPerPage}
                        totalPosts={products?.length}
                        currentPage={currentPage}
                        paginate={paginate}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <div>
                  <h6 style={{ width: "100%" }}>
                    Por el momento no hay productos
                  </h6>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <div className="d-flex align-items-center">
            <img src={filtersIconBlack} alt="Gamerscode" />
            <h5>Filtros</h5>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <h6>Nombre:</h6>
            <Form.Group className="mb-2" controlId="nameAscCheckbox">
              <Form.Check
                type="checkbox"
                label="A - Z"
                name="asc"
                disabled={
                  // isSelected || isPriceSelected ? true : false
                  isInStockSelected || isPriceSelected || isSelected
                    ? isSelected !== "asc"
                    : false
                }
                defaultChecked={filter.order === "asc"}
                onChange={(e) => {
                  // filterSelect("ORDER", e.target.checked, "asc");
                  if (e.target.checked) {
                    !isSelected && setIsSelected(e.target.name);
                    filterSelect("ORDER", e.target.checked, "asc");
                  } else {
                    setIsSelected("");
                    filterSelect("ORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="nameDescCheckbox">
              <Form.Check
                type="checkbox"
                label="Z - A"
                name="desc"
                disabled={
                  // isSelected !== null ||
                  // isSelected !== "desc" ||
                  // isPriceSelected
                  //   ? true
                  //   : false
                  isInStockSelected || isPriceSelected || isSelected
                    ? isSelected !== "desc"
                    : false
                }
                defaultChecked={filter.order === "desc"}
                onChange={(e) => {
                  // filterSelect("ORDER", e.target.checked, "desc");
                  if (e.target.checked) {
                    !isSelected && setIsSelected(e.target.name);
                    filterSelect("ORDER", e.target.checked, "desc");
                  } else {
                    setIsSelected("");
                    filterSelect("ORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
          </div>
          <div>
            <h6>Precio:</h6>
            <Form.Group className="mb-2" controlId="higherPriceCheckbox">
              <Form.Check
                type="checkbox"
                label="Ascendente"
                name="priceAsc"
                defaultChecked={filter.priceOrder === "priceAsc"}
                disabled={
                  // isPriceSelected ? isPriceSelected !== "priceAsc" : false
                  isInStockSelected || isSelected || isPriceSelected
                    ? isPriceSelected !== "priceAsc"
                    : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    !isPriceSelected && setIsPriceSelected(e.target.name);
                    filterSelect("PRICEORDER", e.target.checked, "priceAsc");
                  } else {
                    setIsPriceSelected("");
                    filterSelect("PRICEORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="lowerPriceCheckbox">
              <Form.Check
                type="checkbox"
                label="Descendente"
                name="priceDesc"
                defaultChecked={filter.priceOrder === "priceDesc"}
                disabled={
                  isInStockSelected || isSelected || isPriceSelected
                    ? isPriceSelected !== "priceDesc"
                    : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    !isPriceSelected && setIsPriceSelected(e.target.name);
                    filterSelect("PRICEORDER", e.target.checked, "priceDesc");
                  } else {
                    setIsPriceSelected("");
                    filterSelect("PRICEORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
          </div>
          <div>
            <h6>Stock:</h6>
            <Form.Group className="mb-2" controlId="withBoxCheckbox">
              <Form.Check
                type="checkbox"
                label="En stock"
                name="inStock"
                disabled={isSelected || isPriceSelected ? true : false}
                defaultChecked={filter.condition === "inStock"}
                onChange={(e) => {
                  if (e.target.checked) {
                    !isInStockSelected && setIsInStockSelected(e.target.name);
                    filterSelect("CONDITION", e.target.checked, "inStock");
                  } else {
                    setIsInStockSelected("");
                    filterSelect("CONDITION", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            {/* <Form.Group className="mb-2" controlId="withoutBoxCheckbox">
              <Form.Check
                type="checkbox"
                label="Sin caja"
                name="withOutBox"
                disabled={
                  isConditionSelected
                    ? isConditionSelected !== "withOutBox"
                    : false
                }
                defaultChecked={filter.condition === "withOutBox"}
                onChange={(e) => {
                  if (e.target.checked) {
                    !isConditionSelected &&
                      setIsConditionSelected(e.target.name);
                    filterSelect("CONDITION", e.target.checked, "withOutBox");
                  } else {
                    setIsConditionSelected("");
                    filterSelect("CONDITION", e.target.checked, "");
                  }
                }}
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-2" controlId="conditionGoodCheckbox">
              <Form.Check type="checkbox" label="Bueno" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="conditionRegularCheckbox">
              <Form.Check type="checkbox" label="Regular" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="conditionBadCheckbox">
              <Form.Check type="checkbox" label="Malo" />
            </Form.Group> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Catalogue;
