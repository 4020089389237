/*
  @route    GET https://gamerscode.mx/dashboard/api/webpage/listing/${kind}/${platform}/${classic}/${lower}/${higher}
  @desc     Get all products from specific platform
  @access   Public
*/
export async function getPlatformProducts(kind, platform) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/listing/${kind}/${platform}/False/0/100000`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://gamerscode.mx/dashboard/api/webpage/singlestock/PRODUCT_ID/Game/With_Box
  @desc     Get a single product from specific platform
  @access   Public
*/
export async function getPlatformProduct(id) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/singlestock/${id}/Game/With_Box`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://gamerscode.mx/dashboard/api/webpage/singlestock/PRODUCT_ID/Game/Without_Box
  @desc     Get a single product from specific platform No Box
  @access   Public
*/
export async function getPlatformProductNoBox(id) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/singlestock/${id}/Game/Without_Box`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://gamerscode.mx/dashboard/api/webpage/singlestock/PRODUCT_ID/Game/Without_Box
  @desc     Get all products from a specific retro category
  @access   Public
*/
export async function getRetroCategoryProducts(categoryName, currentRange) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/v2/webstore/retrogames/platform/${categoryName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
