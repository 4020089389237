/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/signup
  @desc     Register a new user
  @access   Public
  @params   email, password, lastNameFather, lastNameMother, name
*/
export async function registerUser(
  email,
  password,
  lastNameFather,
  lastNameMother,
  name
) {
  const data = JSON.stringify({
    email,
    password,
    lastNameFather,
    lastNameMother,
    name,
  });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/signup`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // Origin: "https://gamerscode.mx",
      },
      // referrerPolicy: "no-referrer",
      body: data,
    };
    const response = await fetch(url, params);
    // const result = await response.json();
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    PUT https://gamerscode.mx/dashboard/api/webstore/users/details
  @desc     Edit a user with userID created in register
  @access   Public
  @params   userID, lastNameFather, lastNameMother, name, phone
*/
export async function updateUserID(
  userID,
  lastNameFather,
  lastNameMother,
  name,
  phone
) {
  const data = JSON.stringify({
    userID,
    lastNameFather,
    lastNameMother,
    name,
    phone,
  });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/details`;
    const params = {
      method: "PUT",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // Origin: "https://gamerscode.mx",
      },
      // referrerPolicy: "no-referrer",
      body: data,
    };
    const response = await fetch(url, params);
    // const result = await response.json();
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    PUT https://gamerscode.mx/dashboard/api/webstore/users/details
  @desc     Edit a user with userID created in register
  @access   Public
  @params   userID, lastNameFather, lastNameMother, name, phone
*/
export async function login(email, password) {
  const data = JSON.stringify({
    email,
    password,
  });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/signin`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // Origin: "https://gamerscode.mx",
      },
      // referrerPolicy: "no-referrer",
      body: data,
    };
    const response = await fetch(url, params);
    // const result = await response.json();
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    PUT https://gamerscode.mx/dashboard/api/webstore/users/resetpassword
  @desc     Reset user password with valid email
  @access   Public
  @params   email
*/
export async function recoverPassword(email) {
  const data = JSON.stringify({
    email,
  });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/resetpassword`;
    const params = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        // Origin: "https://gamerscode.mx",
      },
      // referrerPolicy: "no-referrer",
      body: data,
    };
    const response = await fetch(url, params);
    // const result = await response.json();
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://sandbox.gamerscode.mx/webstore/users/sales/USERID
  @desc     Get all sales from a user
  @access   Public
  @params   USERID
*/
export async function getUserSales(userId) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/sales/${userId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/checkemail
  @desc     Validate a previous email
  @access   Public
  @params   oobCode
*/
export async function checkEmail(oobCodeUser) {
  const oobCode = JSON.stringify({ oobCode: oobCodeUser });
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/checkemail`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: oobCode,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/setnewpassword
  @desc     Validate a previous email
  @access   Public
  @params   oobCode
*/
export async function resetPassword(oobCodeUser, newPasswordUser) {
  const data = JSON.stringify({
    oobCode: oobCodeUser,
    newPassword: newPasswordUser,
  });
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/setnewpassword`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET PROCESS.ENV.REACT_APP_API_URL/webpage/reviews/PRODUCT_ID
  @desc     GET all reviews from a game
  @access   Public
  @params   productID
*/

export async function getGameReviews(productID) {
  // const data = JSON.stringify({ productID });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/reviews/${productID}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST PROCESS.ENV.REACT_APP_API_URL/webpage/reviews/validation
  @desc     POST if an existing user has bought a game
  @access   Private
  @params   userID, productID
*/

export async function validateIfGamePurchased(userID, productID) {
  const data = JSON.stringify({ userID, productID });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/reviews/validation`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST PROCESS.ENV.REACT_APP_API_URL/webpage/reviews
  @desc     POST a review from user
  @access   Private
  @params   userID, userName, productID, rating, review
*/

export async function postUserReview(
  userID,
  userName,
  productID,
  rating,
  review
) {
  const data = JSON.stringify({ userID, userName, productID, rating, review });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/reviews`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}
