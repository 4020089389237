import { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Context
import AlertContext from "../contexts/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

// Custom Components
import ReCAPTCHA from "react-google-recaptcha";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// API
import { checkEmail, resetPassword } from "../api/user";

// Custom styles
import "../scss/pages/emailConfirm.styles.scss";

// Assets
import errorIcon from "../assets/img/error-icon--red.svg";
import successIcon from "../assets/img/success-icon--green.svg";

const schema = yup.object().shape({
  newPassword: yup.string().required("Este campo es requerido"),
});

function EmailConfirmation() {
  const [loading, setLoading] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [error, setError] = useState("");

  const alert = useContext(AlertContext);
  const captcha = useRef(null);
  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const oobCode = query.get("oobCode");
  const mode = query.get("mode");
  // console.log(mode);

  const onChange = (value) => {
    setError(false);
    // console.log("Captcha value:", value);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (mode === "verifyEmail") {
        const response = await checkEmail(oobCode);
        // console.log(response);
        if (response.status === 200) {
          // console.log(response);
          setLoading(false);
          return;
        } else {
          response.json().then((json) => {
            if (json.error === "INVALID_OOB_CODE") {
              setError("Código de verificación inválido.");
            }
          });
          setLoading(false);
          return;
        }
      }
      if (mode === "resetPassword") {
        setLoading(false);
        return;
      }
    })();
  }, [oobCode]);

  return (
    <section className="email-confirm__wrapper">
      <Container>
        {mode === "verifyEmail" && (
          <div className="email-confirm__content">
            <h1>Confirmación de correo</h1>
            {loading && !error ? (
              <>
                <Spinner animation="border" variant="primary" />
                <p>Por favor, espera un momento</p>
              </>
            ) : (
              <></>
            )}
            {!loading && error && (
              <div className="email-confirm__content__feedback">
                <img src={errorIcon} alt="Error" />{" "}
                <p className="error">{error}</p>
              </div>
            )}
            {!loading && !error && (
              <div className="email-confirm__content__feedback">
                <img src={successIcon} alt="Error" /> <p>Correo confirmado</p>
              </div>
            )}
          </div>
        )}
        {mode === "resetPassword" && (
          <div className="recover__content">
            <h4>Restablecer contraseña</h4>
            <p className="mb-3 mb-lg-0">
              Ingresa a continuación, la nueva contraseña para tu cuenta
            </p>
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                if (captcha.current.getValue()) {
                  setResetPasswordLoading(true);
                  const response = await resetPassword(
                    oobCode,
                    values.newPassword
                  );
                  // console.log(response);
                  if (response.status === 200) {
                    history.push("/login");
                    alert.success(
                      "Contraseña restablecida. Por favor inicia sesión."
                    );

                    setResetPasswordLoading(false);
                    resetForm({
                      newPassword: "",
                    });
                    captcha.current.reset();
                    return;
                  } else {
                    response.json().then((json) => {
                      // alert.error(json);
                      if (json.error === "INVALID_OOB_CODE") {
                        alert.error(
                          "El código de recuperación ya expiró. Vuelve a solicitar el cambio de contraseña."
                        );
                      } else {
                        alert.error(
                          "Hubo un error en tu solicitud. Vuelve a solicitar el cambio de contraseña."
                        );
                      }
                    });
                    // console.log("error");
                    // alert.error("Error en tu solicitud.");
                    setResetPasswordLoading(false);
                    resetForm({
                      newPassword: "",
                    });
                    captcha.current.reset();
                    return;
                  }
                } else {
                  alert.error("Por favor completa el captcha");
                }
              }}
              initialValues={{
                newPassword: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mt-3" controlId="formBasicEmail">
                    <Form.Control
                      type="password"
                      placeholder="Escribe aquí la nueva contraseña"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.newPassword && !!errors.newPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword || touched.newPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="re-captcha text-center mb-3">
                    <ReCAPTCHA
                      sitekey="6LdTsuAhAAAAAOnxlNbWBtKhB3ceqASkCTEXtUHG"
                      onChange={onChange}
                      ref={captcha}
                    />
                  </div>
                  <button disabled={resetPasswordLoading} type="submit">
                    {resetPasswordLoading ? "Validando..." : "Enviar"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Container>
    </section>
  );
}

export default EmailConfirmation;
