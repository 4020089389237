import { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

// Context
import AlertContext from "../contexts/AlertContext";

// API
import { recoverPassword } from "../api/user";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Custom Components
import ReCAPTCHA from "react-google-recaptcha";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// Custom styles
import "../scss/pages/recover.styles.scss";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un email válido.")
    .required("Este campo es requerido"),
});

function Recover() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const alert = useContext(AlertContext);
  const captcha = useRef(null);

  const history = useHistory();

  const onChange = (value) => {
    setError(false);
    // console.log("Captcha value:", value);
  };

  return (
    <section className="recover__wrapper">
      <div className={`overlay ${loading && "show"}`}></div>
      <Container>
        <div className="recover__content">
          <h4>Recuperación de contraseña</h4>
          <p className="mb-3 mb-lg-0">
            Ingresa a continuación, el correo electrónico que utilizaste para tu
            registro.
          </p>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              if (captcha.current.getValue()) {
                setLoading(true);
                const response = await recoverPassword(values.email);
                // console.log(response);
                if (response.status === 200) {
                  // history.push("/");
                  history.push("/login");
                  alert.success(
                    "Revisa la bandeja de entrada de tu correo electrónico."
                  );
                  setLoading(false);
                } else {
                  response.json().then((json) => {
                    if (json.error === "EMAIL_NOT_FOUND") {
                      alert.error("Email no encontrado");
                    }
                  });
                  setLoading(false);
                }
                resetForm({
                  email: "",
                });
                captcha.current.reset();
              } else {
                alert.error("Por favor completa el captcha");
              }
            }}
            initialValues={{
              email: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mt-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Escribe aquí tu correo"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email || touched.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="re-captcha text-center mb-3">
                  <ReCAPTCHA
                    sitekey="6LdTsuAhAAAAAOnxlNbWBtKhB3ceqASkCTEXtUHG"
                    onChange={onChange}
                    ref={captcha}
                  />
                </div>
                <button type="submit">
                  {loading ? "Enviando correo..." : "Enviar"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}

export default Recover;
