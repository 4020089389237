import React, { useState, useEffect } from "react";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

// Custom styles
import "../scss/pages/profile.styles.scss";

// API
import { getUserSales } from "../api/user";

// Context
import { useAuth } from "../contexts/AuthContext";

// Utils
import formatMoney from "../utils/formatMoney";

// const pastOrders = [
//   {
//     reference: "GC1662013830622",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "30",
//         Sale: "150",
//         Exchange: "50",
//         ID_Info: "10385",
//         ID_Retro_Game: "0",
//         Name: "TitanFall 2",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Prueba",
//         outdoorNumber: "Prueba",
//         state: "Prueba",
//         street: "Prueba",
//         colony: "Prueba",
//         reference: "Prueba",
//         fullName: "Prueba",
//       },
//     ],
//     date: "2022-09-01 01:30:35",
//     subtotal: "150",
//     total: "195",
//   },
//   {
//     reference: "GC1662015411674",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "30",
//         Sale: "150",
//         Exchange: "50",
//         ID_Info: "10385",
//         ID_Retro_Game: "0",
//         Name: "TitanFall 2",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Prueba",
//         outdoorNumber: "Prueba",
//         state: "Prueba",
//         street: "Prueba",
//         colony: "Prueba",
//         reference: "Prueba",
//         fullName: "Rodrigo Zenteno",
//       },
//     ],
//     date: "2022-09-01 01:56:57",
//     subtotal: "150",
//     total: "195",
//   },
//   {
//     reference: "GC1663112665644",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "80",
//         Sale: "599",
//         Exchange: "90",
//         ID_Info: "12021",
//         ID_Retro_Game: "0",
//         Name: "A Way Out",
//         Platform: "Xbox One",
//       },
//       {
//         Purchase: "90",
//         Sale: "199",
//         Exchange: "120",
//         ID_Info: "6792",
//         ID_Retro_Game: "0",
//         Name: "Batman Arkham Knight",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Aguascalientes",
//         outdoorNumber: "Jardines del Lago",
//         state: "Aguascalientes",
//         street:
//           "Blvrd Juan Pablo II 1070, Canteras de Santa Imelda, Pirules, 20218 Aguascalientes, Ags.",
//         colony: "Jardines del Lago",
//         reference: "casa blanca",
//         fullName: "contact@quintech.com.mx",
//       },
//     ],
//     date: "2022-09-13 18:44:30",
//     subtotal: "798",
//     total: "843",
//   },
//   {
//     reference: "GC1663112736644",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "80",
//         Sale: "599",
//         Exchange: "90",
//         ID_Info: "12021",
//         ID_Retro_Game: "0",
//         Name: "A Way Out",
//         Platform: "Xbox One",
//       },
//       {
//         Purchase: "90",
//         Sale: "199",
//         Exchange: "120",
//         ID_Info: "6792",
//         ID_Retro_Game: "0",
//         Name: "Batman Arkham Knight",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Aguascalientes",
//         outdoorNumber: "Jardines del Lago",
//         state: "Aguascalientes",
//         street:
//           "Blvrd Juan Pablo II 1070, Canteras de Santa Imelda, Pirules, 20218 Aguascalientes, Ags.",
//         colony: "Jardines del Lago",
//         reference: "casa blanca",
//         fullName: "contact@quintech.com.mx",
//       },
//     ],
//     date: "2022-09-13 18:45:40",
//     subtotal: "798",
//     total: "843",
//   },
//   {
//     reference: "GC1663112246243",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "80",
//         Sale: "599",
//         Exchange: "90",
//         ID_Info: "12021",
//         ID_Retro_Game: "0",
//         Name: "A Way Out",
//         Platform: "Xbox One",
//       },
//       {
//         Purchase: "90",
//         Sale: "199",
//         Exchange: "120",
//         ID_Info: "6792",
//         ID_Retro_Game: "0",
//         Name: "Batman Arkham Knight",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Aguascalientes",
//         outdoorNumber: "Jardines del Lago",
//         state: "Aguascalientes",
//         street:
//           "Blvrd Juan Pablo II 1070, Canteras de Santa Imelda, Pirules, 20218 Aguascalientes, Ags.",
//         colony: "Jardines del Lago",
//         reference: "casa blanca",
//         fullName: "contact@quintech.com.mx",
//       },
//     ],
//     date: "2022-09-13 18:37:31",
//     subtotal: "798",
//     total: "843",
//   },
//   {
//     reference: "GC1663112416179",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "80",
//         Sale: "599",
//         Exchange: "90",
//         ID_Info: "12021",
//         ID_Retro_Game: "0",
//         Name: "A Way Out",
//         Platform: "Xbox One",
//       },
//       {
//         Purchase: "90",
//         Sale: "199",
//         Exchange: "120",
//         ID_Info: "6792",
//         ID_Retro_Game: "0",
//         Name: "Batman Arkham Knight",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Prueba",
//         outdoorNumber: "Prueba",
//         state: "Prueba",
//         street: "Prueba",
//         colony: "Prueba",
//         reference: "Prueba",
//         fullName: "Rodrigo Zenteno",
//       },
//     ],
//     date: "2022-09-13 18:40:21",
//     subtotal: "798",
//     total: "843",
//   },
//   {
//     reference: "GC1662013770779",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "30",
//         Sale: "150",
//         Exchange: "50",
//         ID_Info: "10385",
//         ID_Retro_Game: "0",
//         Name: "TitanFall 2",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "20218",
//         town: "Prueba",
//         outdoorNumber: "Prueba",
//         state: "Prueba",
//         street: "Prueba",
//         colony: "Prueba",
//         reference: "Prueba",
//         fullName: "Prueba",
//       },
//     ],
//     date: "2022-09-01 01:29:36",
//     subtotal: "150",
//     total: "195",
//   },
//   {
//     reference: "GC1662015575176",
//     shipment: "45",
//     userID: "djvbcltutxQ6Cxinzh0LggvZWFa2",
//     paymentMethod: "Stripe",
//     status: "Pendiente",
//     trackNumber: "No Disponible",
//     salesDetail: [
//       {
//         Purchase: "80",
//         Sale: "599",
//         Exchange: "90",
//         ID_Info: "12021",
//         ID_Retro_Game: "0",
//         Name: "A Way Out",
//         Platform: "Xbox One",
//       },
//       {
//         Purchase: "200",
//         Sale: "899",
//         Exchange: "270",
//         ID_Info: "12957",
//         ID_Retro_Game: "0",
//         Name: "Ace Combat 7 Skies Unknown",
//         Platform: "Xbox One",
//       },
//     ],
//     shippingAddress: [
//       {
//         interiorNumber: "",
//         phone: "4491205871",
//         zip: "21018",
//         town: "Prueba",
//         outdoorNumber: "Prueba",
//         state: "Prueba",
//         street: "Prueba",
//         colony: "Prueba",
//         reference: "Prueba",
//         fullName: "Rodrigo Zenteno",
//       },
//     ],
//     date: "2022-09-01 01:59:40",
//     subtotal: "1498",
//     total: "1543",
//   },
// ];

function Profile() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  // console.log(currentUser);
  useEffect(() => {
    if (currentUser.localId) {
      (async () => {
        setLoading(true);
        const response = await getUserSales(currentUser.localId);
        // console.log(
        //   response.sort((a, b) =>
        //     b.date > a.date ? 1 : a.date > b.date ? -1 : 0
        //   )
        // );
        // console.log(response);
        if (response.error === true) {
          setOrders(0);
          setLoading(false);
          return;
        }
        setOrders(
          response.sort((a, b) =>
            b.date > a.date ? 1 : a.date > b.date ? -1 : 0
          )
        );
        setLoading(false);
      })();
    }

    // setNoBox(0);
  }, [currentUser.localId]);
  // useEffect(() => {
  //   (() => {
  //     // setLoading(true);
  //     // const response = await getUserSales(currentUser.localId);
  //     // console.log(response);
  //     setOrders(pastOrders);
  //     // setLoading(false);
  //   })();

  //   // setNoBox(0);
  // }, []);
  if (loading) {
    return (
      <section className="profile">
        <div className="profile__jumbotron">
          <h2>Tu perfil</h2>
        </div>
        <div className="profile__orders">
          <h5>Tus ordenes:</h5>
          <div className="profile__orders__container">
            <p>Cargando...</p>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="profile">
      <div className="profile__jumbotron">
        <h2>Tu perfil</h2>
      </div>
      <div className="profile__orders">
        <h5>Tus ordenes{orders?.length > 0 && `(${orders?.length})`}:</h5>
        <div className="profile__orders__container">
          {orders?.length > 0 ? (
            <Row xs={12} md={2} lg={3} xl={4} className="g-3">
              {orders?.map((order, key) => (
                <Col key={key}>
                  <Card
                    style={{ height: "100%" }}
                    className="profile__orders__order"
                  >
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <p>No. Orden:</p>
                        <h6>#{order.reference}</h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <p>Método de pago:</p>
                        <h6>{order.paymentMethod}</h6>
                      </div>
                      <p>Producto(s):</p>
                      <ul>
                        {order.salesDetail.map((product, key) => (
                          <li key={key} className="my-1">
                            {product.Name}
                          </li>
                        ))}
                      </ul>
                      <div className="d-flex align-items-center">
                        <p>Fecha de orden:</p>
                        <h6>{order.date}</h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <p>Total:</p>
                        <h6>{formatMoney(order.total)}</h6>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <>
              <p>No tienes historial de compras.</p>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Profile;
