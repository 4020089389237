// Custom styles
import "../scss/pages/legal.styles.scss";

function Privacy() {
  return (
    <section className="legal">
      <div className="legal__header">
        <h2>Aviso de privacidad</h2>
        <p>Última actualización: 10 de julio del 2020.</p>
      </div>
      <div className="legal__content">
        <ol>
          <li>
            <h4>«Responsable» del tratamiento de sus datos personales.</h4>
            <p>
              La empresa Gamers Code, en lo sucesivo denominado como el
              “Responsable”, con domicilio en calle Andador Madero, número 213
              C, colonia Centro, en Aguascalientes, Ags; con cumplimiento en la
              Ley Federal de Protección de Datos Personales en Posesión de
              Particulares y su Reglamento, en lo consecuente denominado de
              manera conjunta la “Ley”, pone a su disposición el presente Aviso
              de Privacidad, con la finalidad de que conozca la manera en que se
              usan, divulgan y almacenan sus datos personales y cualquier
              información que se recabe a nombre de la empresa Gamers Code a
              través de distintos medios, a efecto de garantizar la privacidad
              de dicha información y el derecho a la autodeterminación
              informativa de las personas. Así mismo, hace de su conocimiento
              las finalidades por las que el Responsable recaba y trata sus
              Datos Personales.
            </p>
          </li>
          <li>
            <h4>
              Finalidades del tratamiento de los datos personales de los
              «Usuarios» para el «Responsable»
            </h4>
            <p>
              El Responsable recabará los datos personales mencionados en el
              artículo 3 fracción V de la Ley, necesarios para el fin de su
              objeto, el cual es en forma sintetizada:
            </p>
            <ul>
              <li>
                <p>
                  Comercio al por menor de discos, discos compactos, casetes,
                  cartuchos, cintas de audio y video
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h4>
              Datos personales que recaba el «Responsable» de sus usuarios.
            </h4>
            <p className="mt-1">
              De conformidad con la Ley, requerimos de su consentimiento expreso
              para el tratamiento de sus Datos Personales y de carácter
              financiero y patrimonial, salvo por las excepciones que establece
              la Ley. Si usted manifiesta su consentimiento para el tratamiento
              de estos Datos, el Responsable se compromete a no tratarlos para
              finalidades distintas de las establecidas en este Aviso de
              Privacidad.
            </p>
            <p className="mt-1">
              Lo primero que se debe hacer para disfrutar de los productos
              proporcionados por la empresa Gamers Code, es registrarse,
              suministrando información personal de manera completa y exacta.
              Podremos solicitar, recabar y almacenar la siguiente Información
              Personal: apodo para operar en el sitio web, nombre, imagen
              personal, número de identificación válida, información como:
              número de teléfono, domicilio, dirección de e-mail, etc.
            </p>
            <p className="mt-1">
              El usuario que se registre a través de su cuenta personal en una
              red social, consiente expresamente que se pueda: tener acceso a la
              información contenida en dicha cuenta, como: intereses, gustos,
              entre otros.
            </p>
            <p className="mt-1">
              Le informamos que sus Datos Personales pueden ser transferidos por
              el Responsable, dentro del país de residencia del Responsable, a
              las instituciones dentro del sistema financiero siempre que se
              autorice en forma expresa por el titular para las condiciones que
              se acepten por escrito entre las partes. Así mismo, le informamos
              que el responsable en base al artículo 14 de la Ley, tomará todas
              las medidas necesarias para que estos terceros a los que el
              Responsable hará transferencia de sus Datos Personales se
              comprometan en los mismos términos del presente Aviso de
              Privacidad y cumplan con las medidas de seguridad y
              confidencialidad correspondientes. Si usted no desea que sus Datos
              Personales sean transferidos a terceros para estos fines, usted lo
              debe comunicar al Responsable a través de la información de
              contacto establecida en el presente Aviso de Privacidad. Si usted
              no manifiesta su oposición sobre dichas transferencias, se
              entenderá que le ha otorgado su consentimiento al responsable para
              realizarlas.
            </p>
          </li>
          <li>
            <h4>Datos personales sensibles</h4>
            <p className="mt-1">
              El «Responsable» le informa que, para cumplir con las finalidades
              previstas en este aviso de privacidad, no requiere recabar de
              usted datos personales sensibles.{" "}
            </p>
          </li>
          <li>
            <h4>Uso de la información</h4>
            <p className="mt-1">
              Para un excelente servicio, y los usuarios no tengan problema
              alguno al realizar las operaciones necesarias. La recolección de
              información nos ayuda a entrar en contacto con el comprador, y
              está solo será utilizada para concluir la operación de compra, sin
              ser empleada por ninguno con fines promocionales, salvo la expresa
              autorización; también servirá para conocer los intereses, mejorar
              promociones y ofertas, suministrar información de los usuarios a
              quienes intervengan en la resolución de disputas.
            </p>
            <p className="mt-1">
              Para facilitar la interacción entre todos los mismos usuarios,
              nuestro servicio permite un acceso limitado a ciertos datos de
              contacto del resto de usuarios, tales como Nombre de usuario,
              Teléfonos, Ciudad y dirección de correo electrónico.
            </p>
          </li>
          <li>
            <h4>
              Opciones y medios para limitar el uso o divulgación de sus datos
              personales
            </h4>
            <p className="mt-1">
              Con el objeto de que usted pueda limitar el uso y divulgación de
              su información personal, puede suscribirse en el Registro Público
              para Evitar Publicidad, que está a cargo de la Procuraduría
              Federal del Consumidor, con la finalidad de que sus Datos
              Personales no sean utilizados para recibir publicidad o
              promociones.
            </p>
          </li>
          <li>
            <h4>
              Medios para ejercer los Derechos de Acceso, Rectificación,
              Cancelación y Oposición (Derechos ARCO).
            </h4>
            <p className="mt-1">
              Usted tiene el derecho de acceder a los datos personales que posea
              el «Responsable» y a los detalles del tratamiento de los mismos,
              así como a rectificarlos en caso de ser inexactos o incompletos;
              cancelarlos cuando considere que no se requieren para alguna de
              las finalidades señaladas en el presente aviso de privacidad,
              estén siendo utilizados para finalidades no consentidas o bien
              oponerse al tratamiento de los mismos para fines específicos.
            </p>
            <p className="mt-1">
              El Departamento de Datos Personales del «Responsable», le brindará
              toda la información necesaria, para que usted proceda al ejercicio
              de sus derechos de Acceso, Rectificación, Cancelación y Oposición
              respecto de sus datos personales.
            </p>
          </li>
          <li>
            <h4>
              Procedimiento para que el titular, en su caso, revoque su
              consentimiento para el tratamiento de sus datos personales
            </h4>
            <p className="mt-1">
              En cualquier momento, usted puede revocar el consentimiento que,
              en su caso, haya otorgado al «Responsable» para el tratamiento de
              sus datos personales. Sin embargo, es importante que tenga en
              cuenta que no en todos los casos se podrá atender su solicitud o
              concluir el uso de forma inmediata, ya que es posible que por
              alguna obligación legal se requiera seguir tratando sus datos
              personales. Asimismo, usted deberá considerar que, para ciertos
              fines, la revocación de su consentimiento implicará que no se le
              pueda seguir prestando el servicio que solicitó, o la conclusión
              de su relación con el «Responsable».
            </p>
            <p className="mt-1">
              En caso de que desee revocar su consentimiento al tratamiento de
              sus Datos Personales, deberá presentar su solicitud ante el
              Responsable acompañada de copia de su identificación oficial para
              acreditar que es el titular de los Datos Personales (en su caso,
              la autorización de su representante legal), y señalar el correo
              electrónico o domicilio al que desea recibir la información
              relacionada con el trámite, una descripción clara y precisa de los
              Datos Personales respecto de los cuales requiere revocar su
              consentimiento y los datos que nos permitan notificarle la
              resolución recaída a su solicitud. El responsable le notificará la
              resolución correspondiente a su solicitud dentro de los 30 días
              hábiles siguientes a la presentación de la misma, siempre que esta
              se encuentre completa y debidamente integrada.
            </p>
          </li>
          <li>
            <h4>Solicitud para ejercer Derechos ARCO</h4>
            <p className="mt-1">
              Toda solicitud de ejercicio de derechos ARCO deberá contener la
              siguiente información:
            </p>
            <ul>
              <li>Nombre del titular de los datos personales.</li>
              <li>
                Documentos que acrediten la identidad del titular: La solicitud
                se deberá acompañar de copia simple de una identificación
                oficial de usted como titular de los datos personales, así como
                de su representante legal, en caso de que éste sea quien
                presente la solicitud. Entre las identificaciones oficiales
                válidas se encuentran: credencial para votar, pasaporte,
                cartilla militar, cédula profesional, licencia para conducir y
                documento migratorio.
              </li>
              <li>
                En su caso, nombre del representante legal del titular y
                documentos para acreditar su identidad y personalidad. La
                personalidad de su representante legal, en su caso, se podrá
                acreditar, cuando se trate de persona física, se podrá elegir
                cualquiera de las siguientes tres opciones:
              </li>
              <ul>
                <li>
                  La presentación de una carta poder simple suscrita ante dos
                  testigos, anexando copia simple de sus identificaciones
                  oficiales;
                </li>
                <li>
                  Mediante instrumento público (documento suscrito por un
                  Notario Público)
                </li>
                <li>
                  Acudiendo usted y su representante a declarar en comparecencia
                  ante el responsable.
                </li>
              </ul>
              <li>Especificar el medio para recibir notificaciones.</li>
              <li>
                Descripción clara y precisa de los datos personales que se
                quieran rectificar, cancelar u oponerse a su tratamiento.
              </li>
              <li>
                Descripción del derecho que se quiere ejercer o de lo que
                solicita el titular.
              </li>
              <li>
                En su caso, documentos o información que faciliten la
                localización de los datos personales, entre ella, la unidad
                administrativa responsable del tratamiento.
              </li>
            </ul>
            <p className="mt-1">
              Además de la información general antes señalada, dependiendo del
              derecho que desee ejercer, deberá incluir la siguiente información
              en la solicitud:
            </p>
            <ul>
              <li>
                Derecho de ACCESO: la modalidad en la que prefiere que se
                reproduzcan los datos personales solicitados.
              </li>
              <li>
                Derecho de RECTIFICACIÓN: las modificaciones que solicita que se
                realicen a los datos personales, así como aportar los documentos
                que sustenten la solicitud.
              </li>
              <li>
                Derecho de CANCELACIÓN: las causas que motivan la petición de
                que se eliminen los datos de los archivos, registros o bases de
                datos del responsable del tratamiento.
              </li>
              <li>
                Derecho de OPOSICIÓN: las causas o la situación que lo llevan a
                solicitar que finalice el tratamiento de sus datos personales,
                así como el daño o perjuicio que le causaría que dicho
                tratamiento continúe; o bien, deberá indicar las finalidades
                específicas respecto de las cuales desea ejercer este derecho.
              </li>
            </ul>
            <p className="mt-1">
              El ejercicio de los derechos ARCO será sencillo y gratuito tomando
              en cuenta el artículo 35 de la Ley, sólo podrán realizarse cobros
              para recuperar los costos de reproducción, certificación de
              documentos o envío de información.
            </p>
          </li>
          <li>
            <h4>
              Transferencias de datos personales dentro de México y al
              extranjero
            </h4>
            <p className="mt-1">
              Sus datos personales pueden ser transferidos y tratados dentro y
              fuera del país, si fuere necesario para las finalidades
              mencionadas en el párrafo segundo del punto 3 del presente aviso
              de privacidad. En este caso, se compartirían sus datos personales
              fuera del país, con las instituciones dentro del sistema
              financiero que se requiriera, de acuerdo a los servicios que haya
              solicitado al responsable.
            </p>
            <p className="mt-1">
              El «Responsable» se compromete a no transferir sus datos
              personales a terceros, salvo que por los servicios que se le
              presten, debieran transferirse para dar cumplimiento a
              obligaciones legales ante las autoridades competentes, tales como
              autoridades tributarias o autoridades judiciales, federales o
              locales. Igualmente podrá llevar a cabo transferencia de sus datos
              sin su consentimiento en los supuestos siguientes:
            </p>
            <ul>
              <li>
                Cuando la transferencia esté prevista en una Ley o Tratado en
                los que México sea parte;
              </li>
              <li>
                Cuando la transferencia sea necesaria para la prevención o el
                diagnóstico médico, la prestación de asistencia sanitaria,
                tratamiento médico o la gestión de servicios sanitarios;
              </li>
              <li>
                Cuando la transferencia sea necesaria por virtud de un contrato
                celebrado o por celebrar en interés del titular, por el
                responsable y un tercero;
              </li>
              <li>
                Cuando la transferencia sea necesaria o legalmente exigida para
                la salvaguarda de un interés público, o para la procuración o
                administración de justicia;{" "}
              </li>
              <li>
                Cuando la transferencia sea precisa para el reconocimiento,
                ejercicio o defensa de un derecho en un proceso judicial, y;
              </li>
              <li>
                Cuando la transferencia sea precisa para el mantenimiento o
                cumplimiento de una relación jurídica entre el responsable y el
                titular.
              </li>
            </ul>
            <p className="mt-1">
              En el entendido de que las transferencias se realizarían en los
              términos que fija la Ley Federal de Protección a los Datos
              Personales en Posesión de los Particulares.
            </p>
          </li>
          <li>
            <h4>Medidas de seguridad implementadas</h4>
            <p className="mt-1">
              Para la protección de sus datos personales nos apegamos a lo
              siguiente:
            </p>
            <ul>
              <li>
                El tratamiento de los datos se llevará a cabo con apego y
                cumplimiento a la Legislación mexicana.
              </li>
              <li>
                En el tratamiento de los datos personales, se observarán
                cabalmente los principios de protección de datos: licitud,
                consentimiento, información, calidad, finalidad, lealtad,
                proporcionalidad y responsabilidad.
              </li>
              <li>
                La obtención de datos personales no se realizará bajo ningún
                motivo a través de medios engañosos o fraudulentos, los datos
                personales se recabarán y tratarán de manera clara y lícita.
              </li>
              <li>
                Por lo tanto, todo colaborador que tenga acceso a los mismos,
                velará por el cumplimiento de los mencionados principios de
                protección de datos personales.{" "}
              </li>
              <li>
                Todo tratamiento de datos estará sujeto al consentimiento de su
                titular, salvo las excepciones previstas por la Ley.{" "}
              </li>
              <li>
                El consentimiento de uso de datos personales podrá ser revocado
                en cualquier momento por el titular, para lo cual en este aviso
                de privacidad se establecen los mecanismos y procedimientos para
                ello.{" "}
              </li>
              <li>
                Tratándose de datos personales sensibles, se deberá obtener el
                consentimiento expreso y por escrito del titular para su
                tratamiento.{" "}
              </li>
              <li>
                Todo colaborador procurará que los datos personales que se
                traten, sean pertinentes, correctos y actualizados para los
                fines para los cuales fueron recabados.{" "}
              </li>
              <li>
                El tratamiento de los datos deberá limitarse a las finalidades
                previstas en el Aviso de privacidad. Si se pretende tratar los
                datos para finalidades distintas no compatibles o análogas a los
                fines establecidos en el aviso, se requerirá obtener nuevamente
                el consentimiento del titular.{" "}
              </li>
              <li>
                El tratamiento de los datos será el que resulte necesario,
                adecuado y relevante en relación con las finalidades previstas
                en el Aviso de privacidad.{" "}
              </li>
              <li>
                Se deberá supervisar el adecuado cumplimiento de la presente
                política, así como verificar que el departamento o departamentos
                correspondientes almacene física o digitalmente los datos
                personales que reciban, así como dar seguimiento al uso y
                tratamiento de los datos y atender a toda persona o entidad que
                requiera del acceso, ratificación, cancelación u oposición de
                sus datos, conforme a los procedimientos legales establecidos.{" "}
              </li>
              <li>
                Una vez, que se recabe el consentimiento tácito o expreso del
                titular de los datos, el acuse o consentimiento por escrito del
                aviso de privacidad correspondiente se almacenará en el
                expediente que le corresponda, así como de ser necesarios los
                acuses de las notificaciones que contengan las modificaciones al
                aviso o los nuevos avisos cuyo consentimiento se recabe.{" "}
              </li>
              <li>
                Se verificará que todo empleado, colaborador, proveedor o
                terceros con los cuales se tenga alguna relación jurídica, que
                tengan celebrado el convenio de confidencialidad, a través del
                cual se garantice que el aviso de privacidad dado a conocer al
                titular de los datos será respetado en todo momento por el
                responsable, los colaboradores o empleados de la organización o
                por los terceros con los cuales se guarde alguna relación
                jurídica.{" "}
              </li>
              <li>
                También se verificará el cumplimiento de las medidas de
                seguridad administrativas, técnicas y físicas que permitan
                proteger los datos personales contra daños, pérdida, alteración,
                destrucción o el uso, acceso o tratamiento no autorizado.{" "}
              </li>
              <li>
                Se resguardará y protegerán los datos personales contra daño,
                pérdida, alteración, destrucción o el uso, acceso o tratamiento
                no autorizados.{" "}
              </li>
              <li>
                No podrán crearse bases de datos que contengan datos personales
                sensibles, sin que se justifique la creación de las mismas para
                finalidades legítimas, concretas y acordes con las actividades o
                fines explícitos que persigue el sujeto regulado.{" "}
              </li>
              <li>
                Estaremos obligados a cerciorarse de que los datos de carácter
                personal que hayan dejado de ser necesarios para el cumplimiento
                de las finalidades previstas en el aviso de privacidad y las
                disposiciones legales aplicables, sean cancelados.{" "}
              </li>
              <li>
                Los responsables de las bases de datos, estarán obligados a
                eliminar la información relativa al incumplimiento de
                obligaciones contractuales, una vez que transcurra un plazo de
                setenta y dos meses, contados a partir de la fecha calendario en
                que se presente el mencionado incumplimiento.{" "}
              </li>
              <li>
                ADEMÁS DE LAS PROHIBICIONES DERIVADAS DE LA LEY, LOS
                RESPONSABLES DEL TRATAMIENTO DE DATOS Y/O ENCARGADOS DEL ÁREA
                RESPONSABLE, ESTARÁN SUJETO A LAS SIGUIENTES PROHIBICIONES
              </li>
              <ol>
                <li>
                  Dar tratamiento a los datos en contravención a los principios
                  establecidos en la Ley.
                </li>
                <li>
                  Mantener datos personales inexactos o no efectuar las
                  rectificaciones o cancelaciones de los mismos.{" "}
                </li>
                <li>
                  Declarar dolosamente la inexistencia de datos personales,
                  cuando exista total o parcialmente en las bases de datos del
                  responsable.{" "}
                </li>
                <li>Incumplir con el deber de confidencialidad.</li>
                <li>
                  Cambiar sustancialmente la finalidad originaria del
                  tratamiento de los datos sin presentar al titular un nuevo
                  aviso de privacidad.
                </li>
                <li>
                  Transferir datos a terceros sin comunicar a éstos el aviso de
                  privacidad que contenga las limitaciones a que el titular
                  sujetó la divulgación de los mismos.
                </li>
                <li>
                  Llevar a cabo la transferencia o cesión de datos personales,
                  fuera de los casos previstos por la ley.{" "}
                </li>
                <li>
                  Transferir datos personales sin el consentimiento expreso del
                  titular, cuando éste sea necesario.{" "}
                </li>
                <li>
                  Vulnerar la seguridad de bases de datos, programas y equipos.
                </li>
                <li>
                  Obstruir los actos de verificación de cumplimiento de
                  obligaciones en el tratamiento de los datos.{" "}
                </li>
                <li>
                  Crear bases de datos sensibles en contravención a la Ley.{" "}
                </li>
                <li>
                  No cumplir con la solicitud del titular para el acceso,
                  rectificación, cancelación, u oposición al tratamiento de sus
                  datos personales, sin razón fundada.{" "}
                </li>
                <li>
                  Actuar con negligencia o dolo en la tramitación y respuesta de
                  las solicitudes de acceso, rectificación, cancelación, u
                  oposición de datos personales.
                </li>
              </ol>
            </ul>
          </li>
          <li>
            <h4>Modificaciones al aviso de privacidad</h4>
            <p className="mt-1">
              El presente aviso de privacidad puede sufrir modificaciones,
              cambios o actualizaciones derivadas de nuevos requerimientos
              legales, de las propias necesidades del «Responsable» por los
              servicios que se ofrecen, de las prácticas de privacidad o por
              otras causas, por lo que el «Responsable» se reserva el derecho de
              efectuar en cualquier momento las modificaciones o actualizaciones
              que sean necesarias al presente aviso de privacidad.
            </p>
            <p className="mt-1">
              El «Responsable» pondrá a disposición del donante, la versión
              actualizada del aviso de privacidad, de manera física en las
              instalaciones de la asociación.
            </p>
          </li>
          <li>
            <h4>
              Derecho de promover los procedimientos de protección de derechos y
              de verificación que sustancia el Instituto
            </h4>
            <p className="mt-1">
              Cualquier queja o información adicional respecto al tratamiento de
              sus datos personales o duda en relación con la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares o
              con su Reglamento, podrá dirigirla al Instituto Federal de Acceso
              a la Información y Protección de Datos (IFAI).
            </p>
          </li>
        </ol>
      </div>
    </section>
  );
}

export default Privacy;
