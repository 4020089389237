import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

// API
import { registerUser, updateUserID } from "../api/user";

// Context
import AlertContext from "../contexts/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Custom styles
import "../scss/pages/register.styles.scss";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

import ReactGA from "react-ga4";

const schema = yup.object().shape({
  name: yup.string().required("Este campo es requerido"),
  lastNameFather: yup.string().required("Este campo es requerido"),
  //lastNameMother: yup.string().required("Este campo es requerido"),
  phone: yup.number().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  terms: yup
    .bool()
    .required()
    .oneOf(
      [true],
      "Los términos y condiciones deben ser aceptados para continuar"
    ),
});

function Register() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const alert = useContext(AlertContext);

  return (
    <section className="register__wrapper">
      <div className={`overlay ${loading && "show"}`}></div>
      <Container fluid>
        <Row>
          <Col xs="12" lg="6" style={{ backgroundColor: "#eeeaf0" }}>
            <div className="register__perks">
              <h3>Registro</h3>
              <p className="mt-2">
                Ventas a distancia. Si tienes juegos que te interesa vender,
                pero no tienes una sucursal cerca de ti:
              </p>
              <ul>
                <li>
                  Envíanos una lista de tus juegos/consolas o accesorios a{" "}
                  <a href="mailto:vendetusjuegos@gamerscode.mx">
                    vendetusjuegos@gamerscode.mx
                  </a>{" "}
                  y te contestaremos con una cotización
                </li>
                <li>Te podemos pagar en efectivo o en crédito de la tienda.</li>
                <li>
                  Todos los juegos tienen garantía y han sido probados antes de
                  enviarse.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" lg="6">
            <div className="register__form">
              <h5>Para registrarte, llena los campos a continuación:</h5>
              <span>Los campos marcados * son requeridos</span>
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                  setLoading(true);
                  try {
                    const response = await registerUser(
                      values.email,
                      values.password,
                      values.lastNameFather,
                      values.lastNameMother,
                      values.name
                    );
                    // console.log(response);
                    if (response.status === 200) {
                      response.json().then(async (json) => {
                        // console.log(json.localId);
                        const updatedUser = await updateUserID(
                          json.localId,
                          values.lastNameFather,
                          values.lastNameMother,
                          values.name,
                          values.phone
                        );
                        if (updatedUser.status === 200) {
                          updatedUser.json().then((json) => {
                            // console.log(json);
                            if (json.length === 1) {
                              alert.success(
                                `¡Registro exitoso! Revisa la bandeja de tu correo electrónico para validar tu cuenta.`
                              );

                              ReactGA.event({
                                category: "User",
                                action: "Created an Account",
                              });

                              history.push("/login");
                              setLoading(false);
                            } else {
                              alert.error("Error al registrar usuario.");
                              setLoading(false);
                            }
                          });
                        } else {
                          alert.error("Error al registrar usuario.");
                          setLoading(false);
                        }
                      });
                    } else {
                      //console.log("error");
                      alert.error(
                        "Error al registrar usuario. Verifica los datos y que no tengas una cuenta con nosotros."
                      );
                      setLoading(false);
                    }
                    // console.log(response);
                    // console.log(response);
                  } catch (error) {
                    console.log(error);
                    setLoading(false);
                  }
                }}
                initialValues={{
                  name: "",
                  lastNameFather: "",
                  lastNameMother: "",
                  phone: "",
                  email: "",
                  password: "",
                  terms: false,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Form.Group
                        as={Col}
                        lg="6"
                        controlId="formRegisterName"
                        className="mb-3"
                      >
                        <Form.Label>Nombre(s):*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Escribe tu nombre"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.name && touched.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name || touched.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg="6"
                        controlId="formRegisterSurname"
                        className="mb-3"
                      >
                        <Form.Label>Apellido Paterno:*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Escribe tu apellido paterno"
                          name="lastNameFather"
                          value={values.lastNameFather}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            !!errors.lastNameFather && touched.lastNameFather
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastNameFather || touched.lastNameFather}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        lg="6"
                        controlId="formRegisterSurname2"
                        className="mb-3"
                      >
                        <Form.Label>Apellido Materno:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Escribe tu apellido materno"
                          name="lastNameMother"
                          value={values.lastNameMother}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            !!errors.lastNameMother && touched.lastNameMother
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastNameMother || touched.lastNameMother}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg="6"
                        controlId="formRegisterPhone"
                        className="mb-3"
                      >
                        <Form.Label>Número de teléfono:*</Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Escribe tu número de teléfono"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.phone && touched.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone || touched.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="formRegisterEmail">
                      <Form.Label>Email:*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Escribe tu correo electrónico"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email || touched.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formRegisterPassword"
                    >
                      <Form.Label>Contraseña:*</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Escribe una contraseña"
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.password && touched.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password || touched.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check type="checkbox">
                        <Form.Check.Input
                          type="checkbox"
                          isValid={false}
                          name="terms"
                          onChange={handleChange}
                          isInvalid={!!errors.terms && touched.terms}
                          feedback={errors.terms}
                          // feedbackType="invalid"
                        />
                        <Form.Check.Label>
                          Acepta los{" "}
                          <Link to="/terms">términos y condiciones</Link>
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.terms}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <button type="submit">
                      {loading ? "Registrando..." : "Registrarme ahora"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Register;
