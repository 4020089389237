import { useContext, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

// Context
import { useAuth } from "../../contexts/AuthContext";
import { useCart } from "../../contexts/CartContext";
import AlertContext from "../../contexts/AlertContext";

// Custom components
import Alert from "../Alert/Alert";

// Hooks
import useFetch from "../../hooks/useFetch";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";

// react-bootstrap-submenu styles
import "react-bootstrap-submenu/dist/index.css";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

// Custom styles
import "./customNav.styles.scss";

// Assets
import socialNetwork1 from "../../assets/img/wa-icon-Bg--black.svg";
import socialNetwork2 from "../../assets/img/phone-icon-Bg--black.svg";
import socialNetwork3 from "../../assets/img/ig-icon-Bg--black.svg";
import socialNetwork4 from "../../assets/img/fb-icon-Bg--black.svg";
import socialNetwork5 from "../../assets/img/tk-icon-Bg--black.svg";
import storeIcon from "../../assets/img/store-icon--white.svg";
import logo from "../../assets/img/logo.png";
import shoppingBagIcon from "../../assets/img/shopping-bag-icon.svg";
import greenConsoleIcon from "../../assets/img/console--green.svg";
import accessoriesIcon from "../../assets/img/accesories-icon--grey.svg";
import discIcon from "../../assets/img/disc-icon--grey.svg";
import profileIcon from "../../assets/img/profile-icon--black.svg";
import shipmentTruckIcon from "../../assets/img/shipment-truck-icon.svg";
import branchesIcon from "../../assets/img/branches-icon--black.svg";

const branches = [
  {
    id: 1,
    address:
      "Sucursal Aguascalientes Avenida Madero 213-C Colonia Centro Servicio al Cliente: 477 390 9521",
    schedule: [
      "Lunes 10am - 8pm.",
      "Martes 10am - 8pm.",
      "Miércoles 10am - 8pm.",
      "Jueves 10am - 8pm.",
      "Viernes 10am - 8pm.",
      "Sábado 10am - 8pm.",
      "Domingo 11am - 6pm.",
    ],
  },
  {
    id: 2,
    address:
      "Sucursal Aguascalientes Centro Comercial Villasuncion Local 15b Servicio al Cliente: 477 390 9521",
    schedule: [
      "Lunes 10am - 9pm.",
      "Martes 10am - 9pm.",
      "Miércoles 10am - 9pm.",
      "Jueves 10am - 9pm.",
      "Viernes 10am - 9pm.",
      "Sábado 10am - 9pm.",
      "Domingo 11am - 8pm.",
    ],
  },
  {
    id: 3,
    address:
      "Sucursal León Aquiles Serdan 241-C Colonia Centro Servicio al Cliente: 477 390 9521",
    schedule: [
      "Lunes 10am - 8pm.",
      "Martes 10am - 8pm.",
      "Miércoles 10am - 8pm.",
      "Jueves 10am - 8pm.",
      "Viernes 10am - 8pm.",
      "Sábado 10am - 8pm.",
      "Domingo 11am - 6pm.",
    ],
  },
  {
    id: 4,
    address:
      "Sucursal Zacatecas Ramon Lopez Velarde 521 Colonia Centro Servicio al Cliente: 477 390 9521",
    schedule: [
      "Lunes 10am - 8pm.",
      "Martes 10am - 8pm.",
      "Miércoles 10am - 8pm.",
      "Jueves 10am - 8pm.",
      "Viernes 10am - 8pm.",
      "Sábado 10am - 8pm.",
      "Domingo 11am - 6pm.",
    ],
  },
];

function CustomNav() {
  const { currentUser, setUser } = useAuth();
  const { cartItems, setCartItems } = useCart();

  const alert = useContext(AlertContext);

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cartItems");
    alert.success(`Saliste de tu cuenta.`);

    setUser({});
    setCartItems([]);
    history.push("/");
  };

  const guestLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cartItems");
    alert.success(`Saliste del modo invitado.`);

    setUser({});
    setCartItems([]);
    history.push("/");
  };

  // console.log(Object.values(currentUser).some((x) => x === true));
  // console.log(Object.keys(user).length === 0);
  const { data, setData } = useFetch();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showPSDropdown, setShowPSDropdown] = useState(false);
  const [showNDropdown, setShowNDDropdown] = useState(false);
  const [showSDropdown, setShowSDropdown] = useState(false);

  // console.log(data);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log(document.getElementsByClassName("dropdown-menu"));

  // console.log(
  //   document.querySelectorAll(".dropdown-submenu-container .dropdown-menu")
  // );

  const toggleDropdownMenu = () => {
    let dropdown = document.querySelectorAll(
      ".dropdown-submenu-container > .dropdown-menu"
    );
    // dropdown.map((item, key) => item.classList.remove("show"));
    return Array.from(dropdown).map((elem) => {
      elem.classList.remove("show");
    });
  };
  return (
    <>
      <div className="top-nav">
        <div className="top-nav__social">
          <a href="https://wa.me/524773909521" target="_blank" rel="noreferrer">
            <img src={socialNetwork1} alt="Gamerscode" />
          </a>
          <a
            href="tel:524499188951"
            target="_blank"
            rel="noreferrer"
            className="d-none d-lg-inline-block"
          >
            <img src={socialNetwork2} alt="Gamerscode" />
          </a>
          <a
            href="https://www.instagram.com/gamers_code/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={socialNetwork3} alt="Gamerscode" />
          </a>
          <a
            href="https://www.facebook.com/GamersCode/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={socialNetwork4} alt="Gamerscode" />
          </a>
          <a
            href="https://www.tiktok.com/@gamers_codemx"
            target="_blank"
            rel="noreferrer"
          >
            <img src={socialNetwork5} alt="Gamerscode" />
          </a>
        </div>
        <div className="top-nav__promotion">
          <img src={shipmentTruckIcon} alt="Gamerscode" />
          <h6>¡Envíos gratis a partir de $999.00!</h6>
        </div>
        <div className="top-nav__branches" onClick={handleShow}>
          <img src={storeIcon} className="me-1" alt="GamersCode" />
          <span>Conoce nuestras sucursales</span>
          {/* <a href="">
          </a> */}
        </div>
      </div>
      <Navbar collapseOnSelect className="custom-nav" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="Gamerscode" />
          </Navbar.Brand>
          <div className="align-items-end navbar-actions--mobile">
            {!currentUser.localId && !currentUser.guest && (
              <>
                <Link to="/login" className="navbar-auth me-2">
                  Ingresa
                </Link>
                <Link to="/login" className="navbar-shopping-bag">
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>0</span>
                </Link>
              </>
            )}
            {currentUser.localId && (
              <>
                <NavDropdown
                  title={<img src={profileIcon} alt="Gamerscode" />}
                  id="user-nav-dropdown"
                >
                  <NavDropdown.Item as={NavLink} to="/profile">
                    Mi perfil
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>
                    Cerrar sesión
                  </NavDropdown.Item>
                </NavDropdown>
                <Link to="/profile" className="navbar-auth"></Link>
                <Link to="/cart" className="navbar-shopping-bag">
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>{cartItems?.length}</span>
                </Link>
              </>
            )}
            {currentUser.guest === true && (
              <>
                {/* TO-DO: */}
                {/* - Crea una cuenta should validate to delete {guest: true} en local storage al momento del registro */}
                {/* <Link to="/register" className="navbar-auth">
                  Crea una cuenta
                </Link> */}
                <p className="guest__logout me-2" onClick={guestLogout}>
                  Salir modo invitado
                </p>
                <Link to="/cart" className="navbar-shopping-bag" eventKey={62}>
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>{cartItems?.length}</span>
                </Link>
              </>
            )}

            {/* <img
              src={branchesIcon}
              alt="Gamerscode"
              onClick={handleShow}
              className="branches"
            /> */}
            {/* <a href="" className="navbar-auth">
              Ingresa
            </a>
            <a href="" className="navbar-shopping-bag">
              <img src={shoppingBagIcon} alt="Gamerscode" />
              <span>0</span>
            </a> */}
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div className="navbar__search">
            <Form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault(); // prevents default form action
                history.push(`/results/${data.slug}`); // navigate
                setData({ slug: "" });
              }}
            >
              <FormControl
                type="search"
                placeholder="Buscar juegos, accesorios y más..."
                aria-label="Search"
                value={data.slug}
                onChange={(e) => setData({ ...data, slug: e.target.value })}
              />
              <div
                className={`navbar__search__results ${
                  data.slug !== "" && "show"
                }`}
              >
                <div className="navbar__search__results__container">
                  {data.slug !== "" &&
                    data.results?.Names?.map((result, key) => (
                      // data.results?.Names?.slice(0, 5).map((result, key) => (
                      <Link
                        key={key}
                        to={`/product/${data.results?.ID[key]}`}
                        onClick={() => {
                          setData({ slug: "" });
                        }}
                      >
                        <img
                          src={`https://gamerscode.mx/img/products/${data.results?.ID[key]}.jpg`}
                          alt={result}
                        />
                        {result}
                      </Link>
                    ))}
                  {data.slug !== "" && data.results?.Names?.length === 0 && (
                    <h6>No hay productos</h6>
                  )}
                </div>
              </div>

              {/* <Button variant="outline-success">Buscar</Button> */}
            </Form>
          </div>
          <div className="align-items-center navbar-perk">
            <img src={greenConsoleIcon} alt="Gamerscode" />
            <Link className="mb-0" to="/vendetusjuegos">
              ¡Intercambia tus juegos con nosotros!
            </Link>
          </div>
        </Container>
        <Container>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2 me-lg-auto mx-lg-auto my-lg-auto">
              <Nav.Link as={NavLink} to="/" eventKey={1}>
                Inicio
              </Nav.Link>
              <NavDropdownMenu
                title="Xbox"
                id="navbarXboxDropdown"
                // onToggle={() => toggleDropdownMenu}
                show={showDropdown}
                onToggle={() => {
                  // console.log("enter");
                  if (showDropdown === false) {
                    // console.log("false");
                    setShowDropdown(true);
                  } else {
                    setShowDropdown(false);
                  }
                }}
              >
                <div id="navbarXboxDropdownContent">
                  <DropdownSubmenu title="Xbox Series X">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Xbox Series X"
                      eventKey={2}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/Xbox Series X"
                      eventKey={3}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu title="Xbox One">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Xbox One"
                      eventKey={4}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/Xbox One"
                      eventKey={5}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>

                  <DropdownSubmenu title="Xbox 360">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Xbox 360"
                      eventKey={6}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/catalogue/game/Xbox 360"
                      eventKey={7}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/game/Xbox"
                    // to="/retro/xbox"
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowDropdown(false);
                    }}
                  >
                    Xbox
                  </NavDropdown.Item> */}
                  <DropdownSubmenu title="Xbox">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={Link}
                      to="/catalogue/accessory/Xbox"
                      eventKey={56}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/catalogue/game/Xbox"
                      eventKey={57}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item>Something else here</NavDropdown.Item> */}
                </div>
              </NavDropdownMenu>
              <NavDropdownMenu
                title="PlayStation"
                id="navbarPlayStationDropdown"
                show={showPSDropdown}
                onToggle={() => {
                  // console.log("enter");
                  if (showPSDropdown === false) {
                    // console.log("false");
                    setShowPSDropdown(true);
                  } else {
                    setShowPSDropdown(false);
                  }
                }}
              >
                <div id="navbarPlayStationDropdownContent">
                  <DropdownSubmenu title="PlayStation 5">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PS5"
                      eventKey={10}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey={11}
                      as={NavLink}
                      to="/catalogue/game/PS5"
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu title="PlayStation 4">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PS4"
                      eventKey={12}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PS4"
                      eventKey={13}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu title="PlayStation 3">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PS3"
                      eventKey={14}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PS3"
                      eventKey={15}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/PS2"
                    eventKey={16}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowPSDropdown(false);
                    }}
                  >
                    PlayStation 2
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/PS1"
                    eventKey={17}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowPSDropdown(false);
                    }}
                  >
                    PlayStation 1
                  </NavDropdown.Item> */}
                  <DropdownSubmenu title="PlayStation 2">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PS2"
                      eventKey={51}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PS2"
                      eventKey={52}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu title="PlayStation 1">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PS1"
                      eventKey={49}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PS1"
                      eventKey={50}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu title="PlayStation Vita">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PSVITA"
                      eventKey={18}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PSVITA"
                      eventKey={19}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item
                    as={NavLink}
                    to="/retro/psp"
                    eventKey={20}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowPSDropdown(false);
                    }}
                  >
                    Sony PSP
                  </NavDropdown.Item> */}
                  <DropdownSubmenu href="#action/3.7" title="Sony PSP">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/PSP"
                      eventKey={54}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/PSP"
                      eventKey={55}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowPSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item>Something else here</NavDropdown.Item> */}
                </div>
              </NavDropdownMenu>
              <NavDropdownMenu
                title="Nintendo"
                id="navbarNintendoDropdown"
                show={showNDropdown}
                onToggle={() => {
                  // console.log("enter");
                  if (showNDropdown === false) {
                    // console.log("false");
                    setShowNDDropdown(true);
                  } else {
                    setShowNDDropdown(false);
                  }
                }}
              >
                <div id="navbarNintendoDropdownContent">
                  <DropdownSubmenu href="#action/3.7" title="Nintendo Switch">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Switch"
                      eventKey={21}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/Switch"
                      eventKey={22}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Nintendo Wii U">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/WIIU"
                      eventKey={23}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/WIIU"
                      eventKey={24}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Nintendo Wii">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/WII"
                      eventKey={25}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/WII"
                      eventKey={26}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Nintendo 3DS">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/N3DS"
                      eventKey={27}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/N3DS"
                      eventKey={28}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item as={NavLink} to="/retro/nds">
                    Nintendo DS
                  </NavDropdown.Item> */}
                  <DropdownSubmenu href="#action/3.7" title="Nintendo DS">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/NDS"
                      eventKey={29}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/NDS"
                      eventKey={30}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/GameCube"
                    eventKey={31}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowNDDropdown(false);
                    }}
                  >
                    GameCube
                  </NavDropdown.Item> */}
                  {/* <DropdownSubmenu href="#action/3.7" title="GameCube">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/GameCube"
                      eventKey={60}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/GameCube"
                      eventKey={61}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu> */}
                  <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/GameCube"
                    eventKey={60}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowNDDropdown(false);
                    }}
                  >
                    GameCube
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/N64"
                    eventKey={32}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowNDDropdown(false);
                    }}
                  >
                    {/* <img src={discIcon} alt="GamersCode" /> */}
                    Nintendo 64
                  </NavDropdown.Item>
                  {/* <DropdownSubmenu href="#action/3.7" title="Nintendo 64">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item as={NavLink} to="/catalogue/game/N64">
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu> */}
                  <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/SNES"
                    eventKey={33}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowNDDropdown(false);
                    }}
                  >
                    {/* <img src={discIcon} alt="GamersCode" /> */}
                    Super Nintendo
                  </NavDropdown.Item>
                  {/* <DropdownSubmenu href="#action/3.7" title="Super Nintendo">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/SNES"
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/catalogue/game/SNES">
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu> */}
                  <NavDropdown.Item
                    as={NavLink}
                    to="/retro/nes"
                    eventKey={34}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowNDDropdown(false);
                    }}
                  >
                    {/* <img src={discIcon} alt="GamersCode" /> */}
                    Nintendo NES
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item as={NavLink} to="/retro/gba">
                    GameBoy Advance
                  </NavDropdown.Item> */}
                  <DropdownSubmenu href="#action/3.7" title="GameBoy Advance">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/GBA"
                      eventKey={35}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/GBA"
                      eventKey={36}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item as={NavLink} to="/retro/gameboy color">
                    GameBoy Color
                  </NavDropdown.Item> */}
                  <DropdownSubmenu href="#action/3.7" title="GameBoy Color">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/GameBoy Color"
                      eventKey={37}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/GameBoy Color"
                      eventKey={38}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Item as={NavLink} to="/retro/gameboy">
                    GameBoy
                  </NavDropdown.Item> */}
                  <DropdownSubmenu href="#action/3.7" title="GameBoy">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/GameBoy"
                      eventKey={39}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/GameBoy"
                      eventKey={40}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowNDDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item>Something else here</NavDropdown.Item> */}
                </div>
              </NavDropdownMenu>
              <NavDropdownMenu
                title="Sega"
                id="navbarSegaDropdown"
                show={showSDropdown}
                onToggle={() => {
                  // console.log("enter");
                  if (showSDropdown === false) {
                    // console.log("false");
                    setShowSDropdown(true);
                  } else {
                    setShowSDropdown(false);
                  }
                }}
              >
                <div id="navbarSegaDropdownContent">
                  <DropdownSubmenu title="Sega DreamCast">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/dreamcast"
                      eventKey={58}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/dreamcast"
                      eventKey={59}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>

                  <NavDropdown.Item
                    as={NavLink}
                    to="/catalogue/accessory/Sega Genesis"
                    eventKey={42}
                    onClick={() => {
                      toggleDropdownMenu();
                      setShowSDropdown(false);
                    }}
                  >
                    {/* <img src={discIcon} alt="GamersCode" /> */}
                    Sega Genesis
                  </NavDropdown.Item>

                  {/* <DropdownSubmenu href="#action/3.7" title="">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item href="#action/8.1">
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu> */}

                  <DropdownSubmenu href="#action/3.7" title="Sega Saturn">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Sega Saturn"
                      eventKey={43}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/Sega Saturn"
                      eventKey={44}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>

                  <DropdownSubmenu href="#action/3.7" title="Sega GameGear">
                    <h6>Nuestro catálogo</h6>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/accessory/Sega GameGear"
                      eventKey={45}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={accessoriesIcon} alt="GamersCode" />
                      Consolas y accesorios
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/catalogue/game/Sega GameGear"
                      eventKey={46}
                      onClick={() => {
                        toggleDropdownMenu();
                        setShowSDropdown(false);
                      }}
                    >
                      <img src={discIcon} alt="GamersCode" />
                      Ver juegos
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item>Something else here</NavDropdown.Item> */}
                </div>
              </NavDropdownMenu>
              {/* <NavDropdown title="Retro" id="navbarRetroDropdown" eventKey={6}>
                <NavDropdown.Item href="#action3">Retro</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link as={Link} to="/retro/n64" eventKey={47}>
                Bodega Retro
              </Nav.Link>
              <Nav.Link as={Link} to="/vendetusjuegos" eventKey={48}>
                Vende tus juegos
              </Nav.Link>
              <p
                onClick={handleShow}
                eventKey={63}
                className="d-inline-block d-lg-none"
              >
                Conoce nuestras sucursales
              </p>
              {/* {Object.keys(user).length === 0 ? (
                ""
              ) : (
                <Nav.Link href="#">Cerrar sesión</Nav.Link>
              )} */}
            </Nav>
          </Navbar.Collapse>
          <div className="navbar-actions align-items-end">
            {!currentUser.localId && !currentUser.guest && (
              <>
                <Link to="/login" className="navbar-auth">
                  Ingresa
                </Link>
                <Link to="/login" className="navbar-shopping-bag">
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>0</span>
                </Link>
              </>
            )}
            {currentUser.localId && (
              <>
                <NavDropdown
                  title={<img src={profileIcon} alt="Gamerscode" />}
                  id="user-nav-dropdown"
                >
                  <NavDropdown.Item as={NavLink} to="/profile">
                    Mi perfil
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>
                    Cerrar sesión
                  </NavDropdown.Item>
                </NavDropdown>
                <Link to="/profile" className="navbar-auth"></Link>
                <Link to="/cart" className="navbar-shopping-bag">
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>{cartItems?.length}</span>
                </Link>
              </>
            )}
            {currentUser.guest === true && (
              <>
                {/* TO-DO: */}
                {/* - Crea una cuenta should validate to delete {guest: true} en local storage al momento del registro */}
                {/* <Link to="/register" className="navbar-auth">
                  Crea una cuenta
                </Link> */}
                <p className="guest__logout" onClick={guestLogout}>
                  Salir modo invitado
                </p>
                <Link to="/cart" className="navbar-shopping-bag">
                  <img src={shoppingBagIcon} alt="Gamerscode" />
                  <span>{cartItems?.length}</span>
                </Link>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <Alert />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuestras sucursales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="d-none d-lg-flex">
              <Col xs="12" lg="6" className="branches__location">
                <h6>Oficinas</h6>
              </Col>
              <Col xs="12" lg="6" className="branches__schedule">
                <h6>Horarios</h6>
              </Col>
            </Row>
            {branches.map((branch, key) => (
              <Row className="branch-container" key={key}>
                <Col xs="12" lg="6" className="branches__location">
                  <h6 className="d-lg-none">Oficinas</h6>
                  <p>{branch.address}</p>
                </Col>
                <Col xs="12" lg="6" className="branches__schedule">
                  <h6 className="d-lg-none mt-2">Horarios</h6>
                  <ul>
                    {branch.schedule.map((item, key) => (
                      <li key={key}>{item}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            ))}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomNav;
