/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/shoppingcart/USER_ID
  @desc     Add an item to user's cart with userID
  @access   Public
  @params   userID
*/
export async function addToCart(userID, cartItems) {
  const data = JSON.stringify(cartItems);
  // console.log(
  //   JSON.stringify([{ ...product, ID_Info: productId, Quantity: quantity }])
  // );
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/shoppingcart/${userID}`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://gamerscode.mx/dashboard/api/webstore/users/shoppingcart/USER_ID
  @desc     get all items user's cart with userID
  @access   Public
  @params   userID
*/
export async function getCart(userID) {
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/shoppingcart/${userID}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    DELETE https://gamerscode.mx/dashboard/api/webstore/users/shoppingcart
  @desc     delete all cart items from a userID
  @access   Public
  @params   
*/
export async function emptyCart(userID) {
  const data = JSON.stringify({
    userID,
  });
  // console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/shoppingcart`;
    const params = {
      method: "DELETE",
      // mode: "no-cors",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    // const result = await response.json();
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}
