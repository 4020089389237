import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import FormControl from "react-bootstrap/FormControl";

// Context
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";

// API
import { getRetroCategoryProducts } from "../api/product";

// Custom components
import CustomPagination from "../components/Pagination/CustomPagination";

// Custom styles
import "../scss/pages/retro.styles.scss";

// Utils
import formatMoney from "../utils/formatMoney";

// Assets
// import filtersIcon from "../assets/img/filters-icon.svg";
import filtersIconBlack from "../assets/img/filter-icon--black.svg";
// import product from "../assets/img/product-1.jpg";
import amiibos from "../assets/img/amiibos.png";
import ps2 from "../assets/img/ps2.png";
import ps1 from "../assets/img/ps1.png";
import xbox from "../assets/img/xbox.png";
import gamecube from "../assets/img/gamecube.png";

import nds from "../assets/img/nds.png";
import gbColor from "../assets/img/gameboy-color.png";
import n64 from "../assets/img/n64.png";
import gameboy from "../assets/img/gameboy.png";
import supern from "../assets/img/supern.png";
import nnes from "../assets/img/nnes.png";
import gbAdv from "../assets/img/gameboy-advance.png";
import psp from "../assets/img/psp.png";
import segaDream from "../assets/img/sega-dreamcast.png";
import genesis from "../assets/img/genesis.png";

// const products = [
//   {
//     id: 1,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 2,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 3,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 4,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 5,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 6,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 7,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
//   {
//     id: 8,
//     productName: "ADS Air Force Delta Storm",
//     price: 99,
//     image: product,
//   },
// ];

const categories = [
  {
    id: 1,
    categoryName: "Amiibos/Figuras",
    image: amiibos,
    slug: "Amiibos",
  },
  {
    id: 8,
    categoryName: "N64",
    image: n64,
  },
  {
    id: 9,
    categoryName: "NES",
    image: nnes,
    slug: "nes",
  },
  {
    id: 15,
    categoryName: "SNES",
    image: supern,
    slug: "snes",
  },
  {
    id: 2,
    categoryName: "PS2",
    image: ps2,
  },
  {
    id: 5,
    categoryName: "GameCube",
    image: gamecube,
  },
  {
    id: 3,
    categoryName: "PS1",
    image: ps1,
  },
  {
    id: 4,
    categoryName: "Xbox",
    image: xbox,
  },
  {
    id: 6,
    categoryName: "PSP",
    image: psp,
  },
  {
    id: 10,
    categoryName: "GBA",
    image: gbAdv,
  },
  {
    id: 11,
    categoryName: "GameBoy Color",
    image: gbColor,
    slug: "GameBoy Color",
  },
  {
    id: 12,
    categoryName: "GameBoy",
    image: gameboy,
  },
  {
    id: 7,
    categoryName: "NDS",
    image: nds,
  },
  {
    id: 13,
    categoryName: "Dreamcast",
    image: segaDream,
  },
  {
    id: 14,
    categoryName: "Genesis",
    image: genesis,
    slug: "Sega Genesis",
  },
];

function Retro() {
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  // const [originalProducts, setOriginalProducts] = useState([]);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  // const toggleImageShow = () => setImageShow((s) => !s);

  let { slug } = useParams();

  const [currentRange, setCurrentRange] = useState(0);

  const initFilter = {
    order: "",
    priceOrder: "",
    condition: "",
  };

  const [filter, setFilter] = useState(initFilter);
  const [isSelected, setIsSelected] = useState(null);
  const [isPriceSelected, setIsPriceSelected] = useState(null);
  // const [isInStockSelected, setIsInStockSelected] = useState(null);

  const filterSelect = (type, checked, item) => {
    if (checked) {
      switch (type) {
        case "ORDER":
          setFilter({ ...filter, order: item });
          break;
        case "PRICEORDER":
          setFilter({ ...filter, priceOrder: item });
          break;
        case "CONDITION":
          setFilter({ ...filter, condition: item });
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case "ORDER":
          setFilter(initFilter);
          break;
        case "PRICEORDER":
          setFilter(initFilter);
          break;
        case "CONDITION":
          setFilter(initFilter);
          break;

        default:
          break;
      }
    }
  };

  const updateProducts = () => {
    let temp = products;
    if (isSelected === "desc") {
      temp = temp.sort((a, b) =>
        b.Name > a.Name ? 1 : a.Name > b.Name ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isSelected === "asc") {
      temp = temp.sort((a, b) =>
        a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isPriceSelected === "priceAsc") {
      temp = temp.sort((a, b) =>
        parseInt(b.Sale) > parseInt(a.Sale)
          ? 1
          : parseInt(a.Sale) > parseInt(b.Sale)
          ? -1
          : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    if (isPriceSelected === "priceDesc") {
      temp = temp.sort((a, b) =>
        parseInt(a.Sale) > parseInt(b.Sale)
          ? 1
          : parseInt(b.Sale) > parseInt(a.Sale)
          ? -1
          : 0
      );
      setCurrentPage(1);
      setProducts(temp);
      handleClose();
    }
    // if (isSelected === "" || isPriceSelected === "") {
    //   setProducts(originalProducts);
    // }
    // if (isInStockSelected === "inStock") {
    //   let filteredArray = temp;
    //   filteredArray = filteredArray.filter(
    //     (product) => product.Stock !== "Sin Inventario"
    //   );
    //   setCurrentPage(1);
    //   setProducts(filteredArray);
    //   handleClose();
    // }
    // if (isInStockSelected === "") {
    //   setProducts(originalProducts);
    // }
  };

  useEffect(() => {
    updateProducts();
  }, [isSelected, isPriceSelected, filter]);

  const [loadingUI, setLoadingUI] = useState(false);
  const { currentUser } = useAuth();
  const { addProductToCart, setCartItems, cartItems, guestAddFuProductToCart } =
    useCart();
  const alert = useContext(AlertContext);
  const history = useHistory();

  /* -------- Search Bar implementation starts here -------- */
  const [searchQuery, setSearchQuery] = useState("");

  const search = (event) => {
    setSearchQuery(event.target.value);
  };

  let searchResults = products?.filter((product) => {
    return Object.keys(product).some((key) =>
      product[key]
        .toString()
        .toLowerCase()
        .includes(searchQuery.toString().toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);
  // const [totalRetroGames, setTotalRetroGames] = useState(0);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = searchResults?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setIsSelected("");
      setIsPriceSelected("");
      // setIsInStockSelected("");
      filterSelect("ORDER", null, "");
      setCurrentPage(1);
      setSearchQuery("");
      if (currentPage !== 1) {
        setCurrentRange(currentRange + postsPerPage);
      }
      const response = await getRetroCategoryProducts(slug, currentRange);
      if (response !== null) {
        // setTotalRetroGames(response[response.length - 1]);
        // response.pop();
        setProducts(
          response.sort((a, b) =>
            a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
          )
        );
        // setOriginalProducts(response);
        setLoading(false);
      } else setProducts([]);
    })();
  }, [slug]);

  /* -------- Search Bar implementation ends here -------- */

  return (
    <section className="retro__wrapper">
      <div className={`overlay ${loadingUI && "show"}`}></div>
      <Container fluid>
        <Row>
          {/* 
          <Col xs="12" lg="3">
            <div
              className="catalogue__filters__cta--mobile"
              onClick={toggleShow}
            >
              <img src={filtersIconBlack} alt="Gamerscode" />
              <h6>Filtros</h6>
            </div>
            <div className="catalogue__category">
              <h5>Retro</h5>
            </div>
            <div className="catalogue__filters__cta">
              <img src={filtersIcon} alt="Gamerscode" />
              <h6>Filtros:</h6>
            </div>
            <div className="catalogue__filters">
              <div>
                <h6>Nombre:</h6>
                <Form.Group className="mb-2" controlId="nameAscCheckbox">
                  <Form.Check type="checkbox" label="A - Z" />
                </Form.Group>
                <Form.Group className="mb-2" controlId="nameDescCheckbox">
                  <Form.Check type="checkbox" label="Z - A" />
                </Form.Group>
              </div>
              <div>
                <h6>Precio:</h6>
                <Form.Group className="mb-2" controlId="higherPriceCheckbox">
                  <Form.Check type="checkbox" label="Ascendente" />
                </Form.Group>
                <Form.Group className="mb-2" controlId="lowerPriceCheckbox">
                  <Form.Check type="checkbox" label="Descendente" />
                </Form.Group>
              </div>
              <div>
                <h6>Condición:</h6>
                <Form.Group className="mb-2" controlId="conditionGoodCheckbox">
                  <Form.Check type="checkbox" label="Bueno" />
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="conditionRegularCheckbox"
                >
                  <Form.Check type="checkbox" label="Regular" />
                </Form.Group>
                <Form.Group className="mb-2" controlId="conditionBadCheckbox">
                  <Form.Check type="checkbox" label="Malo" />
                </Form.Group>
              </div> 
            </div>
          </Col>*/}
          <Col xs="12" lg="12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="catalogue__category">
                <h5>Retro</h5>
              </div>
              <div
                className="catalogue__filters__cta--mobile"
                onClick={toggleShow}
              >
                <img src={filtersIconBlack} alt="Gamerscode" />
                <h6>Filtros</h6>
              </div>
            </div>
            <div className="catalogue__container retro__container">
              {/* <h4>{kindSpanish}</h4> */}
              <h6>Todas las categorías retro:</h6>
              <div className="retro__container__retro-catalogue">
                <div className="retro__container__retro-catalogue__cards">
                  {categories.map((category, key) => (
                    <Link
                      to={`/retro/${
                        category.slug
                          ? category.slug.toLocaleLowerCase()
                          : category.categoryName.toLocaleLowerCase()
                      }`}
                      key={key}
                      className="retro__container__retro-catalogue__card"
                    >
                      <img src={category.image} alt={category.categoryName} />
                      <h6>{category.categoryName}</h6>
                    </Link>
                  ))}
                </div>
              </div>
              <p className="mt-3">
                Estos productos se encuentran disponibles para venta en línea,
                si deseas una foto de la condición actual del producto puedes
                enviar un{" "}
                <a
                  href="https://wa.me/524773909521"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Whatsapp
                </a>
                . Todos los productos son probados antes de ser enviados.
              </p>

              {loading ? (
                <h5>Cargando...</h5>
              ) : products?.length !== 0 ? (
                <div className="retro__container__products">
                  <div className="retro__container__products__search-wrapper">
                    <FormControl
                      type="search"
                      placeholder="Buscar juegos..."
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(event) => search(event)}
                      /* value={data.slug}
                              onChange={(e) => setData({ ...data, slug: e.target.value })} */
                    />
                  </div>
                  <h4 className="mb-2">{slug.toUpperCase()}</h4>
                  <Row xs={2} md={2} lg={4} xl={5} xxl={6} className="g-3">
                    {searchResults?.length === 0 ? (
                      /* product.totalGames === "0" ? ( */
                      <Col>
                        <p>No hay productos.</p>
                      </Col>
                    ) : (
                      currentPosts?.map((product, key) => (
                        <Col key={key}>
                          <Card
                            style={{ height: "100%" }}
                            className={`product__card ${
                              product.hasDiscount > 1 && `withDiscount`
                            }`}
                          >
                            {product.hasDiscount > 1 && (
                              <div className="product__card__discount">
                                <span>Descuento solo en línea</span>
                              </div>
                            )}
                            <Card.Img
                              variant="top"
                              className="prodcut__card-retro-img"
                              src={`https://gamerscode.mx/img/retrogames/${product.retroGameID}.JPG`}
                              onClick={(e) => {
                                // console.log(
                                //   e.target.parentElement.parentElement
                                // );
                                if (imageShow) {
                                  e.target.style.transform = "unset";
                                  e.target.style.zIndex = "unset";
                                  e.target.parentElement.parentElement.style.position =
                                    "unset";
                                  setImageShow(false);
                                } else {
                                  e.target.style.transform = "scale(1.5)";
                                  e.target.style.zIndex = "9999999";
                                  e.target.parentElement.parentElement.style.position =
                                    "relative";
                                  // toggleImageShow();
                                  // Animation effect
                                  e.target.style.transition =
                                    "transform 0.25s ease";
                                  setImageShow(true);
                                }
                              }}
                            />
                            <Card.Body>
                              {/* <p className="product__card__price">
                                {formatMoney(product.Sale)}
                              </p> */}
                              <div
                                className={`product__card__price  ${
                                  product.hasDiscount > 1 &&
                                  `product__card__price__withDiscount`
                                }`}
                              >
                                {product.hasDiscount > 1 ? (
                                  <div className="d-flex flex-column">
                                    <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                                      <p>
                                        {formatMoney(product.originalPrice)}
                                      </p>
                                      <span>{product.hasDiscount}% OFF</span>
                                    </div>
                                    <p>{formatMoney(product.Sale)}</p>
                                  </div>
                                ) : (
                                  <p>{formatMoney(product.Sale)}</p>
                                )}
                              </div>
                              {product.grading && (
                                <p className="m-0 mb-1">{product.grading}</p>
                              )}
                              <h5>{product.Name}</h5>
                              <button
                                className="btn--blue"
                                onClick={async () => {
                                  if (currentUser.localId) {
                                    if (cartItems?.length === 0) {
                                      // console.log("creó");
                                      setLoadingUI(true);
                                      const response = await addProductToCart(
                                        currentUser.localId,
                                        [
                                          {
                                            ID_Info: product.ID_Info,
                                            Name: product.Name,
                                            Platform: product.Platform,
                                            Purchase: null,
                                            Exchange: null,
                                            Sale: `${product.Sale}`,
                                            Quantity: "1",
                                            retroGameID: product.retroGameID,
                                          },
                                        ]
                                      );
                                      if (response.status === 200) {
                                        setCartItems([
                                          {
                                            // ...product,
                                            ID_Info: product.ID_Info,
                                            Name: product.Name,
                                            Platform: product.Platform,
                                            Sale: `${product.Sale}`,
                                            Quantity: "1",
                                            typeOfBox: `${product.grading}`,
                                            retroGameID: product.retroGameID,
                                          },
                                        ]);
                                        alert.success(
                                          "Producto añadido a tu bolsa de compra"
                                        );
                                        setLoadingUI(false);
                                      } else {
                                        alert.error(
                                          "Error al agregar producto a la bolsa."
                                        );
                                        setLoadingUI(false);
                                      }
                                    } else {
                                      // console.log("agregó");
                                      setLoadingUI(true);
                                      for (
                                        let i = 0;
                                        i < cartItems.length;
                                        i++
                                      ) {
                                        // const element = cartItems[i];
                                        if (
                                          cartItems[i].ID_Info ===
                                          product.ID_Info
                                        ) {
                                          alert.error(
                                            "Solo puedes comprar una pieza por producto."
                                          );
                                          setLoadingUI(false);
                                          return;
                                        }
                                      }
                                      const response = await addProductToCart(
                                        currentUser.localId,
                                        [
                                          ...cartItems,
                                          {
                                            // ...product,
                                            ID_Info: product.ID_Info,
                                            Name: product.Name,
                                            Platform: product.Platform,
                                            Purchase: "122",
                                            Exchange: "122",
                                            Sale: `${product.Sale}`,
                                            Quantity: "1",
                                            retroGameID: product.retroGameID,
                                          },
                                        ]
                                      );
                                      if (response.status === 200) {
                                        setCartItems(
                                          [
                                            ...cartItems,
                                            {
                                              // ...product,
                                              ID_Info: product.ID_Info,
                                              Name: product.Name,
                                              Platform: product.Platform,
                                              Sale: `${product.Sale}`,
                                              Quantity: "1",
                                              typeOfBox: `${product.grading}`,
                                              retroGameID: product.retroGameID,
                                            },
                                          ],
                                          product.ID_Info
                                        );
                                        alert.success(
                                          "Producto añadido a tu bolsa de compra"
                                        );
                                        setLoadingUI(false);
                                      } else {
                                        alert.error(
                                          "Error al agregar producto a la bolsa."
                                        );
                                        setLoadingUI(false);
                                      }
                                    }
                                  } else if (currentUser.guest === true) {
                                    if (cartItems?.length === 0) {
                                      // console.log("creó");
                                      guestAddFuProductToCart(
                                        {
                                          Name: product.Name,
                                          Platform: product.Platform,
                                          Purchase: "122",
                                          Exchange: "122",
                                          Sale: `${product.Sale}`,
                                          Quantity: "1",
                                          retroGameID: product.retroGameID,
                                        },
                                        product.ID_Info
                                      );
                                      // guestAddFuProductToCart([product.detail]);
                                    } else {
                                      // console.log("agregó");
                                      // console.log(cartItems);
                                      // console.log(cartItems.filter());
                                      for (
                                        let i = 0;
                                        i < cartItems.length;
                                        i++
                                      ) {
                                        // const element = cartItems[i];
                                        if (
                                          cartItems[i].ID_Info ===
                                          product.ID_Info
                                        ) {
                                          alert.error(
                                            "Solo puedes comprar una pieza por producto."
                                          );
                                          return;
                                        }
                                      }
                                      // console.log("no hay pieza en el carrito");
                                      guestAddFuProductToCart([
                                        ...cartItems,
                                        {
                                          Name: product.Name,
                                          Platform: product.Platform,
                                          Purchase: "122",
                                          Exchange: "122",
                                          Sale: `${product.Sale}`,
                                          Quantity: "1",
                                          retroGameID: product.retroGameID,
                                          ID_Info: product.ID_Info,
                                        },
                                      ]);
                                      alert.success(
                                        "Producto añadido a la bolsa de compra"
                                      );
                                    }
                                  } else {
                                    // console.log("first time guest añadir");
                                    guestAddFuProductToCart(
                                      {
                                        Name: product.Name,
                                        Platform: product.Platform,
                                        Purchase: "122",
                                        Exchange: "122",
                                        Sale: `${product.Sale}`,
                                        Quantity: "1",
                                        retroGameID: product.retroGameID,
                                      },
                                      product.ID_Info
                                    );
                                    history.push("/guest");
                                  }
                                }}
                              >
                                Añadir a la bolsa +
                              </button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    )}
                  </Row>
                  <Row>
                    {searchResults?.length >= 40 && (
                      <Col xs="12" xl="12" className="d-flex align-items-end">
                        <CustomPagination
                          postsPerPage={postsPerPage}
                          totalPosts={searchResults?.length}
                          currentPage={currentPage}
                          paginate={paginate}
                          setImageShow={setImageShow}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              ) : (
                <div>
                  <h6 style={{ width: "100%" }}>
                    Por el momento no hay productos
                  </h6>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <div className="d-flex align-items-center">
            <img src={filtersIconBlack} alt="Gamerscode" />
            <h5>Filtros</h5>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <h6>Nombre:</h6>
            <Form.Group className="mb-2" controlId="nameAscCheckbox">
              <Form.Check
                type="checkbox"
                label="A - Z"
                name="asc"
                disabled={
                  // isSelected || isPriceSelected ? true : false
                  isPriceSelected || isSelected ? isSelected !== "asc" : false
                }
                defaultChecked={filter.order === "asc"}
                onChange={(e) => {
                  // filterSelect("ORDER", e.target.checked, "asc");
                  if (e.target.checked) {
                    !isSelected && setIsSelected(e.target.name);
                    filterSelect("ORDER", e.target.checked, "asc");
                  } else {
                    setIsSelected("");
                    filterSelect("ORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="nameDescCheckbox">
              <Form.Check
                type="checkbox"
                label="Z - A"
                name="desc"
                disabled={
                  // isSelected !== null ||
                  // isSelected !== "desc" ||
                  // isPriceSelected
                  //   ? true
                  //   : false
                  isPriceSelected || isSelected ? isSelected !== "desc" : false
                }
                defaultChecked={filter.order === "desc"}
                onChange={(e) => {
                  // filterSelect("ORDER", e.target.checked, "desc");
                  if (e.target.checked) {
                    !isSelected && setIsSelected(e.target.name);
                    filterSelect("ORDER", e.target.checked, "desc");
                  } else {
                    setIsSelected("");
                    filterSelect("ORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
          </div>
          <div>
            <h6>Precio:</h6>
            <Form.Group className="mb-2" controlId="higherPriceCheckbox">
              <Form.Check
                type="checkbox"
                label="Ascendente"
                name="priceAsc"
                defaultChecked={filter.priceOrder === "priceAsc"}
                disabled={
                  // isPriceSelected ? isPriceSelected !== "priceAsc" : false
                  isSelected || isPriceSelected
                    ? isPriceSelected !== "priceAsc"
                    : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    !isPriceSelected && setIsPriceSelected(e.target.name);
                    filterSelect("PRICEORDER", e.target.checked, "priceAsc");
                  } else {
                    setIsPriceSelected("");
                    filterSelect("PRICEORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="lowerPriceCheckbox">
              <Form.Check
                type="checkbox"
                label="Descendente"
                name="priceDesc"
                defaultChecked={filter.priceOrder === "priceDesc"}
                disabled={
                  isSelected || isPriceSelected
                    ? isPriceSelected !== "priceDesc"
                    : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    !isPriceSelected && setIsPriceSelected(e.target.name);
                    filterSelect("PRICEORDER", e.target.checked, "priceDesc");
                  } else {
                    setIsPriceSelected("");
                    filterSelect("PRICEORDER", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
          </div>
          {/* <div>
            <h6>Stock:</h6>
            <Form.Group className="mb-2" controlId="withBoxCheckbox">
              <Form.Check
                type="checkbox"
                label="En stock"
                name="inStock"
                disabled={isSelected || isPriceSelected ? true : false}
                defaultChecked={filter.condition === "inStock"}
                onChange={(e) => {
                  if (e.target.checked) {
                    !isInStockSelected && setIsInStockSelected(e.target.name);
                    filterSelect("CONDITION", e.target.checked, "inStock");
                  } else {
                    setIsInStockSelected("");
                    filterSelect("CONDITION", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="withoutBoxCheckbox">
              <Form.Check
                type="checkbox"
                label="Sin caja"
                name="withOutBox"
                disabled={
                  isConditionSelected
                    ? isConditionSelected !== "withOutBox"
                    : false
                }
                defaultChecked={filter.condition === "withOutBox"}
                onChange={(e) => {
                  if (e.target.checked) {
                    !isConditionSelected &&
                      setIsConditionSelected(e.target.name);
                    filterSelect("CONDITION", e.target.checked, "withOutBox");
                  } else {
                    setIsConditionSelected("");
                    filterSelect("CONDITION", e.target.checked, "");
                  }
                }}
              />
            </Form.Group>
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
}

export default Retro;
