import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";

// API
import { emptyCart } from "../../api/cart";
import { postSale, checkPrices } from "../../api/sales";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Context
import AlertContext from "../../contexts/AlertContext";
import { useCart } from "../../contexts/CartContext";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// Custom styles
import "./paymentForm.styles.scss";

// Stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// Custom components
// import CardSection from "../CardSection/CardSection";

// Assets
import creditCard1 from "../../assets/img/credit-cards_amex.svg";
import creditCard2 from "../../assets/img/credit-cards_mastercard.svg";
import creditCard3 from "../../assets/img/credit-cards_visa.svg";
import formatMoney from "../../utils/formatMoney";
import creditCardIcon from "../../assets/img/credit-card--black.svg";
import cashIcon from "../../assets/img/cash-icon--black.svg";

const initialValues = {
  clientName: "",
  // lastName: "",
  clientEmail: "",
  phoneNumber: "",
  address: "",
  streetNumber: "",
  neighborhood: "",
  reference: "",
  postalCode: 0,
  municipality: "",
  city: "",
  state: "",
};

const phoneRegExp = /^[0-9]{10}$/;

let schema = yup.object().shape({
  clientName: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  clientEmail: yup
    .string()
    .email("Ingresa un email válido.")
    .required("Este campo es requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Por favor proporciona tu número a 10 dígitos")
    .required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  streetNumber: yup.string().required("Este campo es requerido"),
  neighborhood: yup.string().required("Este campo es requerido"),
  reference: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  postalCode: yup
    .number()
    .required("Este campo es requerido")
    .moreThan(10000, "Ingresa un Código Postal válido"),
  municipality: yup.string().required("Este campo es requerido"),
  city: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
});

function PaymentForm({
  shipment,
  setShipment,
  totalPriceCart,
  loading,
  setLoading,
  currentUser,
  guestEmptyCart,
  emptyUserCart,
  getTotalPriceCart,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeError, setStripeError] = useState("");

  const [status, setStatus] = useState(0);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const radioHandler = (status) => {
    setStatus(status);
  };

  const history = useHistory();

  const alert = useContext(AlertContext);
  const { cartItems } = useCart();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const reference = `GC${+new Date()}`;
        const cardNumber = elements.getElement(CardElement);
        const isEmpty = cardNumber._empty;
        const isValid = !isEmpty;
        if (!isValid) {
          alert.error("Ingresa un método de pago válido.");
          document
            .getElementById("card-element")
            .scrollIntoView({ block: "center" });
          return;
        }
        if (shipment.shipmentCost === 0 && totalPriceCart < 999) {
          alert.error("Selecciona un tipo de envío");
          window.scrollTo(0, 0);
          return;
        }
        if (currentUser.guest) {
          setLoading(true);
          const { error: stripeError, paymentMethod } =
            await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardElement),
              billing_details: {
                email: values.clientEmail,
                name: values.clientName,
                phone: values.phoneNumber,
                address: {
                  city: values.city,
                  country: "MX",
                  line1: values.address,
                  line2: values.neighborhood,
                  state: values.state,
                },
              },
            });
          if (paymentMethod) {
            try {
              const response = await checkPrices({
                gamesList: cartItems,
                paymentMethod: "Stripe",
                stripeIntent: paymentMethod.id,
                shippingAddress: {
                  addressName: "Ejemplo",
                  colony: values.neighborhood,
                  default: false,
                  interiorNumber: "",
                  outdoorNumber: values.streetNumber,
                  phone: values.phoneNumber,
                  reference: values.reference,
                  state: values.state,
                  street: values.address,
                  town: values.municipality,
                  zip: values.postalCode,
                  documentID: "Q0QMLU7mGFhdHNXqId1D",
                  fullName: values.clientName,
                },
                userID: currentUser.localId,
                reference,
                status: "Pendiente",
                subtotal: totalPriceCart - shipment.shipmentCost,
                shippingMethod: shipment.shipmentType,
                shipment: shipment.shipmentCost,
                total: totalPriceCart,
                email: values.clientEmail,
              });
              if (response.status === 200) {
                response.json().then(async (json) => {
                  if (json.length > 0 && json[0] === true) {
                    // console.log("lo que sigue");
                    const response = await postSale({
                      gamesList: cartItems,
                      paymentMethod: "Stripe",
                      stripeIntent: paymentMethod.id,
                      shippingAddress: {
                        addressName: "Ejemplo",
                        colony: values.neighborhood,
                        default: false,
                        interiorNumber: "",
                        outdoorNumber: values.streetNumber,
                        phone: values.phoneNumber,
                        reference: values.reference,
                        state: values.state,
                        street: values.address,
                        town: values.municipality,
                        zip: values.postalCode.toString(),
                        documentID: "Q0QMLU7mGFhdHNXqId1D",
                        fullName: values.clientName,
                      },
                      userID: currentUser.localId,
                      reference,
                      status: "Pendiente",
                      subtotal: totalPriceCart - shipment.shipmentCost,
                      shippingMethod: shipment.shipmentType,
                      shipment: shipment.shipmentCost,
                      total: totalPriceCart,
                      email: values.clientEmail,
                      guest: true,
                    });
                    setLoading(false);
                    if (response.status === 500) {
                      setLoading(false);
                      response.json().then((json) => {
                        setStripeError(json.errorMessage);
                      });
                      setTimeout(() => {
                        setStripeError("");
                      }, 5000);
                      return;
                    } else {
                      guestEmptyCart();
                      history.push(`/confirmation/${reference}`);
                      setLoading(false);
                      return;
                    }
                  }
                });
              } else {
                setLoading(false);
                console.log(response);
                alert.error("Error");
              }
            } catch (error) {
              alert.error("Error");
              console.log(error);
              setLoading(false);
            }
          }
          if (stripeError) {
            setLoading(false);
            // console.log(stripeError);
            alert.error(stripeError.message);
            return;
          }
          // console.log(stripeError);
          // console.log(paymentMethod);
          // setTimeout(() => {
          // }, 3000);
          // return;
        }
        if (currentUser.localId) {
          setLoading(true);
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              email: values.clientEmail,
              name: values.clientName,
              phone: values.phoneNumber,
              address: {
                city: values.city,
                country: "MX",
                line1: values.address,
                line2: values.neighborhood,
                state: values.state,
              },
            },
          });
          // console.log(paymentMethod);
          if (paymentMethod) {
            try {
              const response = await checkPrices({
                gamesList: cartItems,
                paymentMethod: "Stripe",
                stripeIntent: paymentMethod.id,
                shippingAddress: {
                  addressName: "Ejemplo",
                  colony: values.neighborhood,
                  default: false,
                  interiorNumber: "",
                  outdoorNumber: values.streetNumber,
                  phone: values.phoneNumber,
                  reference: values.reference,
                  state: values.state,
                  street: values.address,
                  town: values.municipality,
                  zip: values.postalCode,
                  documentID: "Q0QMLU7mGFhdHNXqId1D",
                  fullName: values.clientName,
                },
                userID: currentUser.localId,
                reference: `GC${+new Date()}`,
                status: "Pendiente",
                subtotal: totalPriceCart - shipment.shipmentCost,
                shippingMethod: shipment.shipmentType,
                shipment: shipment.shipmentCost,
                total: totalPriceCart,
                email: values.clientEmail,
              });
              if (response.status === 200) {
                response.json().then(async (json) => {
                  if (json.length > 0 && json[0] === true) {
                    // console.log("lo que sigue");
                    const response = await postSale({
                      gamesList: cartItems,
                      paymentMethod: "Stripe",
                      stripeIntent: paymentMethod.id,
                      shippingAddress: {
                        addressName: "Ejemplo",
                        colony: values.neighborhood,
                        default: false,
                        interiorNumber: "",
                        outdoorNumber: values.streetNumber,
                        phone: values.phoneNumber,
                        reference: values.reference,
                        state: values.state,
                        street: values.address,
                        town: values.municipality,
                        zip: values.postalCode.toString(),
                        documentID: "Q0QMLU7mGFhdHNXqId1D",
                        fullName: values.clientName,
                      },
                      userID: currentUser.localId,
                      reference,
                      status: "Pendiente",
                      subtotal: totalPriceCart - shipment.shipmentCost,
                      shippingMethod: shipment.shipmentType,
                      shipment: shipment.shipmentCost,
                      total: totalPriceCart,
                      email: values.clientEmail,
                      guest: false,
                    });
                    setLoading(false);
                    if (response.status === 500) {
                      setLoading(false);
                      response.json().then((json) => {
                        setStripeError(json.errorMessage);
                      });
                      setTimeout(() => {
                        setStripeError("");
                      }, 5000);
                    } else {
                      const response = await emptyCart(currentUser.localId);
                      // console.log(response);
                      if (response.status === 200) {
                        // console.log(response.status);
                        emptyUserCart();
                        history.push(`/confirmation/${reference}`);
                        // history.push("/confirmation/12ffbb");
                        setLoading(false);
                      } else {
                        setLoading(false);
                        return;
                      }
                    }
                  }
                });
              } else {
                setLoading(false);
                alert.error(
                  "Error en la verificación del precio de un producto."
                );
                const response = await emptyCart(currentUser.localId);
                if (response.status === 200) {
                  // console.log(response.status);
                  guestEmptyCart();
                  setLoading(false);
                } else {
                  setLoading(false);
                  return;
                }
              }
            } catch (error) {
              console.log(error);
              setLoading(false);
            }
          }
        }
        // console.log("submit");

        // setTimeout(() => {
        //   history.push("/confirmation/12ffbb");
        //   setLoading(false);
        // }, 3000);
        // console.log(paymentMethod);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <Form onSubmit={handleSubmit} className="payment-form">
            <div className="payment-form__shipment-type">
              <div className="payment-form__header">
                <h4>
                  <span>1</span>Tipo de envío
                </h4>
              </div>
              <div className="payment-form__shipment-type__container">
                {totalPriceCart < 999 ? (
                  <>
                    <p>Selecciona el tipo de envio:</p>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Check
                        inline
                        label="Envío estándar: $99 pesos (5 - 10 días hábiles)."
                        name="shipment1"
                        type="radio"
                        id="shipment1"
                        value="99"
                        checked={shipment.shipmentCost === 99}
                        onChange={() => {
                          setShipment({
                            shipmentCost: 99,
                            shipmentType: "Estándar",
                          });
                        }}
                        isInvalid={errors.shipment1 && touched.shipment1}
                      />
                      <Form.Check
                        inline
                        label="Envío express: $190 pesos (2 - 5 días hábiles)."
                        type="radio"
                        name="shipment2"
                        id="shipment2"
                        value="190"
                        checked={shipment.shipmentCost === 190}
                        onChange={() => {
                          setShipment({
                            shipmentCost: 190,
                            shipmentType: "Express",
                          });
                        }}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <h6>
                    El total de tu carrito es mayor a $999.00. Tu envío es
                    gratis{" "}
                  </h6>
                )}
              </div>
            </div>
            <div className="payment-form__contact-info">
              <div className="payment-form__header">
                <h4>
                  <span>2</span>Información de contacto
                </h4>
              </div>
              <div className="payment-form__contact-info__container">
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>
                    Nombre completo: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="clientName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientName}
                    isInvalid={!!errors.clientName && touched.clientName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.clientName || touched.clientName}
                  </Form.Control.Feedback>
                  {/* <span
                  style={{
                    color: "#c93030",
                    fontWeight: "bolder",
                    marginTop: ".6rem",
                    padding: "0",
                    fontSize: ".9rem",
                  }}
                >
                  {errors.clientName && touched.clientName && errors.clientName}
                </span> */}
                </Form.Group>
                <Row>
                  <Form.Group as={Col} lg="6" controlId="formGridAddress1">
                    <Form.Label>
                      Email: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="clientEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientEmail}
                      isInvalid={!!errors.clientEmail && touched.clientEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.clientEmail || touched.clientEmail}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.clientEmail &&
                      touched.clientEmail &&
                      errors.clientEmail}
                  </span> */}
                  </Form.Group>
                  <Form.Group as={Col} lg="6" controlId="formGridAddress1">
                    <Form.Label>
                      Teléfono: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      // value={values.phoneNumber}
                      // onChange={handleInputChange}
                      // required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber || touched.phoneNumber}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </span> */}
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="payment-form__contact-info">
              <div className="payment-form__header">
                <h4>
                  <span>3</span>Información de envío
                </h4>
              </div>
              <div className="payment-form__contact-info__container">
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Calle: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      isInvalid={!!errors.address && touched.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address || touched.address}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.address && touched.address && errors.address}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Número: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="streetNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetNumber}
                      isInvalid={!!errors.streetNumber && touched.streetNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streetNumber || touched.streetNumber}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.streetNumber &&
                      touched.streetNumber &&
                      errors.streetNumber}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Referencia: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="reference"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      isInvalid={!!errors.reference && touched.reference}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reference || touched.reference}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.reference && touched.reference && errors.reference}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Colonia: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="neighborhood"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.neighborhood}
                      isInvalid={!!errors.neighborhood && touched.neighborhood}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.neighborhood || touched.neighborhood}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.neighborhood &&
                      touched.neighborhood &&
                      errors.neighborhood}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Código Postal: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      isInvalid={!!errors.postalCode && touched.postalCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.postalCode || touched.postalCode}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.postalCode &&
                      touched.postalCode &&
                      errors.postalCode}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Municipio: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="municipality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.municipality}
                      isInvalid={!!errors.municipality && touched.municipality}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.municipality || touched.municipality}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.municipality &&
                      touched.municipality &&
                      errors.municipality}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Ciudad: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      isInvalid={!!errors.city && touched.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city || touched.city}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.city && touched.city && errors.city}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Estado: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      isInvalid={!!errors.state && touched.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state || touched.state}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.state && touched.state && errors.state}
                  </span> */}
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="payment-form__contact-info">
              <div className="payment-form__header d-flex">
                <h4 className="me-2">
                  <span>4</span>Pago
                </h4>
                <img src={creditCard1} alt="Gamerscode" />
                <img src={creditCard2} className="mx-2" alt="Gamerscode" />
                <img src={creditCard3} alt="Gamerscode" />
              </div>

              {/* <CardElement /> */}
              <div className="payment-form__card-section">
                <p>
                  Selecciona la opción que más te convenga para realizar tu
                  pago:
                </p>
                <div>
                  <div>
                    <div className="d-flex mt-3 mb-2">
                      <input
                        type="radio"
                        id="stripe-payment-selection"
                        className="input-payment-form-option"
                        value="card"
                        onChange={handleChange}
                        checked={status === 1}
                        onClick={(e) => {
                          radioHandler(1);
                          setPaymentMethodType(e.target.value);
                        }}
                      />
                      <label
                        className="mb-0 payment-option-label"
                        htmlFor="stripe-payment-selection"
                      >
                        {" "}
                        {/* <img
                          src={creditCardIcon}
                          alt="Tarjeta de crédito"
                          className="me-2"
                        />{" "} */}
                        {/* <p className="d-inline-block"></p> */}
                        <span className="card-icon"></span>
                        <span>Tarjeta de crédito/débito</span>
                      </label>
                    </div>
                    {status === 1 ? (
                      <fieldset style={{ border: "none" }}>
                        <div className="form-row">
                          <div id="card-element">
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "17px",
                                    height: "60px",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div id="card-errors" role="alert">
                            {stripeError !== "" && <p>{stripeError}</p>}
                          </div>
                        </div>
                      </fieldset>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="mercado-pago">
                  <div className="d-flex mt-3 mb-2">
                    <input
                      type="radio"
                      id="cash-payment-selection"
                      className="input-payment-form-option"
                      value="oxxo"
                      checked={status === 2}
                      onChange={handleChange}
                      onClick={(e) => {
                        radioHandler(2);
                        setPaymentMethodType(e.target.value);
                      }}
                    />
                    <label
                      className="mb-0 payment-option-label"
                      htmlFor="cash-payment-selection"
                    >
                      {/* {" "}
                      <img
                        src={cashIcon}
                        alt="Tarjeta de crédito"
                        className="me-2 cash-icon"
                      />{" "} */}
                      <span className="cash-icon"></span>
                      <span>Pago en efectivo | Oxxo</span>
                    </label>
                  </div>
                  {status === 2 ? (
                    <>
                      <p>
                        El pago en efectivo es controlado por Mercado Pago.
                        Serás redireccionado a su página para completar tu
                        solicitud.
                      </p>

                      {getTotalPriceCart(cartItems) > 0 ? (
                        <button
                          type="button"
                          onClick={async () => {
                            if (
                              shipment.shipmentCost === 0 &&
                              totalPriceCart < 999
                            ) {
                              alert.error("Selecciona un tipo de envío");
                              window.scrollTo(0, 0);
                              return;
                            }
                            if (currentUser.guest) {
                              // window.location.href =
                              //   "https://www.mercadopago.com.mx/checkout/v1/redirect?pref_id=326513703-e0b54978-1074-4927-b66d-7570752cb44d";
                              // guestEmptyCart();
                              try {
                                setLoading(true);
                                const response = await postSale({
                                  gamesList: cartItems,
                                  paymentMethod: "MercadoPago",
                                  shippingAddress: {
                                    addressName: "Ejemplo",
                                    colony: values.neighborhood,
                                    default: false,
                                    interiorNumber: "",
                                    outdoorNumber: values.streetNumber,
                                    phone: values.phoneNumber,
                                    reference: values.reference,
                                    state: values.state,
                                    street: values.address,
                                    town: values.municipality,
                                    zip: values.postalCode.toString(),
                                    documentID: "Q0QMLU7mGFhdHNXqId1D",
                                    fullName: values.clientName,
                                  },
                                  userID: currentUser.localId,
                                  reference: `GC${+new Date()}`,
                                  status: "Pendiente",
                                  subtotal:
                                    totalPriceCart - shipment.shipmentCost,
                                  shippingMethod: shipment.shipmentType,
                                  shipment: shipment.shipmentCost,
                                  total: totalPriceCart,
                                  email: values.clientEmail,
                                  guest: true,
                                });
                                response.json().then((json) => {
                                  window.location.href = json;
                                });
                                guestEmptyCart();
                                // const response2 = await emptyCart(
                                //   currentUser.localId
                                // );
                                // if (response2.status === 200) {
                                //   window.location.href =
                                //     "https://www.mercadopago.com.mx/checkout/v1/redirect?pref_id=326513703-e0b54978-1074-4927-b66d-7570752cb44d";
                                //   guestEmptyCart();
                                // }

                                setLoading(false);
                              } catch (error) {
                                alert.error("Error");
                                console.log(error);
                                setLoading(false);
                              }
                              return null;
                            }
                            if (currentUser.localId) {
                              setLoading(true);
                              const response = await postSale({
                                gamesList: cartItems,
                                paymentMethod: "MercadoPago",
                                shippingAddress: {
                                  addressName: "Ejemplo",
                                  colony: values.neighborhood,
                                  default: false,
                                  interiorNumber: "",
                                  outdoorNumber: values.streetNumber,
                                  phone: values.phoneNumber,
                                  reference: values.reference,
                                  state: values.state,
                                  street: values.address,
                                  town: values.municipality,
                                  zip: values.postalCode.toString(),
                                  documentID: "Q0QMLU7mGFhdHNXqId1D",
                                  fullName: values.clientName,
                                },
                                userID: currentUser.localId,
                                reference: `GC${+new Date()}`,
                                status: "Pendiente",
                                subtotal:
                                  totalPriceCart - shipment.shipmentCost,
                                shippingMethod: shipment.shipmentType,
                                shipment: shipment.shipmentCost,
                                total: totalPriceCart,
                                email: values.clientEmail,
                                guest: false,
                              });
                              response.json().then((json) => {
                                window.location.href = json;
                              });
                              const response2 = await emptyCart(
                                currentUser.localId
                              );
                              if (response2.status === 200) {
                                // window.location.href =
                                //   "https://www.mercadopago.com.mx/checkout/v1/redirect?pref_id=326513703-e0b54978-1074-4927-b66d-7570752cb44d";
                                emptyUserCart();
                              }
                              setLoading(false);
                            }
                            // try {
                            //   // setLoading
                            //   const response = await postSale({
                            //     gamesList: cartItems,
                            //     paymentMethod: "MercadoPago",
                            //     shippingAddress: {
                            //       addressName: "Ejemplo",
                            //       colony: values.neighborhood,
                            //       default: false,
                            //       interiorNumber: "",
                            //       outdoorNumber: values.streetNumber,
                            //       phone: values.phoneNumber,
                            //       reference: values.reference,
                            //       state: values.state,
                            //       street: values.address,
                            //       town: values.municipality,
                            //       zip: values.postalCode.toString(),
                            //       documentID: "Q0QMLU7mGFhdHNXqId1D",
                            //       fullName: values.clientName,
                            //     },
                            //     userID: currentUser.localId,
                            //     reference: `GC${+new Date()}`,
                            //     status: "Pendiente",
                            //     subtotal: totalPriceCart - shipment.shipmentCost,
                            //     shippingMethod: shipment.shipmentType,
                            //     shipment: shipment.shipmentCost,
                            //     total: totalPriceCart,
                            //     email: values.clientEmail,
                            //     guest: true,
                            //   });
                            //   // setLoading(false);
                            //   console.log(response);
                            //   // if (response.status === 500) {
                            //   //   setLoading(false);
                            //   //   response.json().then((json) => {
                            //   //     setStripeError(json.errorMessage);
                            //   //   });
                            //   //   setTimeout(() => {
                            //   //     setStripeError("");
                            //   //   }, 5000);
                            //   //   return;
                            //   // } else {
                            //   //   guestEmptyCart();
                            //   //   history.push(`/confirmation/${reference}`);
                            //   //   setLoading(false);
                            //   //   return;
                            //   // }
                            // } catch (error) {
                            //   alert.error("Error");
                            //   console.log(error);
                            //   setLoading(false);
                            // }
                          }}
                        >
                          {loading
                            ? "Procesando..."
                            : "Completar pago en Mercado Pago"}
                        </button>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {cartItems.length === 0 && status !== 2 ? (
                <h6 className="text-center mt-2">
                  No hay elementos en tu carrito
                </h6>
              ) : status !== 0 &&
                status === 1 &&
                getTotalPriceCart(cartItems) > 0 ? (
                <button
                  type="submit"
                  disabled={loading}
                  className={`${status === 2 && "d-none"} `}
                >
                  {loading
                    ? "Procesando compra..."
                    : `Completar compra ${formatMoney(totalPriceCart)}`}
                </button>
              ) : null}
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default PaymentForm;
