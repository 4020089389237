import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Context
import { AuthProvider } from "./contexts/AuthContext";
import { CartProvider } from "./contexts/CartContext";
import { AlertProvider } from "./contexts/AlertContext";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Custom CSS
import "./scss/main.scss";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SessionTimeout from "./components/SessionTimeout/SessionTimeout";
// import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker";

// Pages
import Home from "./pages/Home";
import Product from "./pages/Product";
import Catalogue from "./pages/Catalogue";
import Cart from "./pages/Cart";
import Confirmation from "./pages/Confirmation";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Guest from "./pages/Guest";
import Cookies from "./pages/Cookies";
import Retro from "./pages/Retro";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import Search from "./pages/Search";
import Recover from "./pages/Recover";
import CashConfirmation from "./pages/CashConfirmation";
import CashConfirmationError from "./pages/CashConfirmationError";

import ReactGA from "react-ga4";
import EmailConfirmation from "./pages/EmailConfirmation";
import PurchaseCatalogue from "./pages/PurchaseCatalogue";

ReactGA.initialize("G-3QLZQP3ZJZ");

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  return (
    <AuthProvider>
      <CartProvider>
        <AlertProvider>
          <Router>
            <header>
              <CustomNav />
            </header>
            <ScrollToTop />

            <Switch>
              <main>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/catalogue/:kind/:platform">
                  <Catalogue />
                </Route>
                <Route exact path="/vendetusjuegos">
                  <PurchaseCatalogue />
                </Route>
                <Route exact path="/cart">
                  <Cart />
                </Route>
                <Route exact path="/cookies">
                  <Cookies />
                </Route>
                <Route exact path="/confirmation/:id">
                  <Confirmation />
                </Route>
                <Route exact path="/cash-confirmation">
                  <CashConfirmation />
                </Route>
                <Route exact path="/cash-confirmation-error">
                  <CashConfirmationError />
                </Route>
                <Route exact path="/emailconfirm/">
                  <EmailConfirmation />
                </Route>
                <Route exact path="/guest">
                  <Guest />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/product/:id">
                  <Product />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/privacy">
                  <Privacy />
                </Route>
                <Route exact path="/recover">
                  <Recover />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
                <Route exact path="/retro/:slug">
                  <Retro />
                </Route>
                <Route exact path="/results/:query">
                  <Search />
                </Route>
                <Route exact path="/terms">
                  <Terms />
                </Route>
              </main>
            </Switch>
            <Footer />
            <SessionTimeout />
            <CookieBanner expires={3} />
            {/* <RouteChangeTracker /> */}
          </Router>
        </AlertProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
