/*
  @route    GET https://api.gamerscode.mx/dashboard/webpage/search/${searchTerm}
  @desc     Get all products based on a search term
  @access   Public
*/
export async function getSearchProduct(searchTerm) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/search/${searchTerm}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://api.gamerscode.mx/webpage/searchlist/${searchTerm}/0/10000000000/NULL
  @desc     Get all products based on a search term
  @access   Public
  @params   searchTerm
*/
export async function getSearchProducts(searchTerm) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/searchlist/${searchTerm}/0/10000000000/NULL`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://api.gamerscode.mx/webpage/featuredpurchase/search/${searchTerm}
  @desc     Get all products based on a search term
  @access   Public
*/
export async function getRetroSearchProduct(searchTerm) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/featuredpurchase/search/${searchTerm}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
