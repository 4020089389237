// Custom styles
import "../scss/pages/legal.styles.scss";

function Terms() {
  return (
    <section className="legal">
      <div className="legal__header">
        <h2>Términos y condiciones</h2>
      </div>
      <div className="legal__content">
        <h4>GARANTÍA DE CONFIANZA EN JUEGOS</h4>
        <p className="mt-2">12 MESES DE GARANTÍA EN DISCOS.</p>
        <p className="mt-2">3 MESES DE GARANTÍA EN CARTUCHOS.</p>
        <ul>
          <li>
            El disco tiene que estar en las mismas condiciones, las cuales serán
            marcadas en el ticket al momento de la compra.
          </li>
          <li>
            Para poder hacer válida la garantía es indispensable presentar el
            ticket de compra, sin excepciones.
          </li>
          <li>
            La garantía no cubre el desgaste de la batería en cartuchos, (snes,
            gba, n64, etc).
          </li>
          <li>
            La garantía no cubre requisitos externos del disco como conexión a
            internet o instalación en disco duro, así como algún problema de la
            consola.
          </li>
          <li>
            En el caso de presentarse un mal funcionamiento en el juego, este se
            cambiará en primer instancia por un juego del mismo título (el mismo
            juego, pero otro disco) si en el momento el juego no se encuentra
            disponible se cambiará por otro del mismo valor al momento de la
            venta.
          </li>
          <li>No hay reembolsos en efectivo.</li>
        </ul>
        <h4>GARANTÍA DE SATISFACCIÓN EN JUEGOS (SEGURO)</h4>
        <ul>
          <li>El seguro solo puede ser pagado en efectivo.</li>
          <li>
            El seguro se tiene que adquirir al momento de la compra del juego,
            no puede ser añadido después.
          </li>
          <li>El seguro dura 4 días sin excepciones.</li>
          <li>El seguro solo puede ser adquirido 2 veces consecutivas.</li>
          <li>El seguro es aplicable a todos los juegos de $799 o menos.</li>
          <li>No hay reembolsos en efectivo.</li>
          <li>
            Para hacer válida la promoción es indispensable presentar el ticket
            de compra, sin excepciones.
          </li>
        </ul>
        <h4>12 MESES DE GARANTÍA EN CONSOLAS</h4>
        <ul>
          <li>
            Tienen que presentarse en las mismas condiciones, las cuales serán
            marcadas en el ticket al momento de la compra.
          </li>
          <li>
            Para poder hacer válida la garantía es indispensable presentar el
            ticket de compra, sin excepciones.
          </li>
          <li>
            Una vez que se realice la transacción, no se efectúan cancelaciones.
          </li>
          <li>La garantía aplica de la siguiente manera:</li>
          <ul>
            <li>Arreglo de la consola con proveedor, si no tiene arreglo.</li>
            <li>
              Reemplazo por un producto similar, so no se encuentra en
              existencia.
            </li>
            <li>
              Reemplazo por otro producto del valor marcado en el ticket de
              compra.
            </li>
          </ul>
          <li>No hay reembolsos en efectivo.</li>
          <li>
            El producto deberá ser probado por al menos 6 horas para validar el
            motivo por el cual se desea aplicar la garantía.
          </li>
        </ul>
        <h4>3 MESES DE GARANTÍA EN ACCESORIOS</h4>
        <p className="mt-2">Discos duros, guitarras, kinects.</p>
        <ul>
          <li>
            Para poder hacer válida la garantía es indispensable presentar el
            ticket de compra, sin excepciones.
          </li>
          <li>
            Una vez que se realice la transacción, no se efectúan cancelaciones.
          </li>
          <li>La garantía aplica de la siguiente manera:</li>
          <ul>
            <li>Arreglo de la consola con proveedor, si no tiene arreglo.</li>
            <li>
              Reembplazo por un producto similar, so no se encuentra en
              existencia.
            </li>
            <li>
              Reemplazo por otro producto del valor marcado en el ticket de
              compra.
            </li>
          </ul>
          <li>No hay reembolsos en efectivo</li>
          <li>
            El producto deberá ser probado por al menos 3 horas para validar el
            motivo por el cual se desea aplicar la garantía
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Terms;
