import React from "react";
import { Link, useHistory } from "react-router-dom";

// Context
import { useAuth } from "../contexts/AuthContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "../scss/pages/guest.styles.scss";

import ReactGA from "react-ga4";

function Guest() {
  const { setUser } = useAuth();
  const history = useHistory();

  return (
    <section className="guest__wrapper">
      <Container fluid className="h-100">
        <Row>
          <Col xs="12" lg="6" style={{ backgroundColor: "#eeeaf0" }}>
            <div className="register__perks">
              <h5>¡Conoce nuestros beneficios de registrarte con nosotros!</h5>
              <ul>
                <li>
                  Envíanos una lista de tus juegos/consolas o accesorios a{" "}
                  <a href="mailto:vendetusjuegos@gamerscode.mx">
                    vendetusjuegos@gamerscode.mx
                  </a>{" "}
                  y te contestaremos con una cotización.
                </li>
                <li>Te podemos pagar en efectivo o en crédito de la tienda.</li>
                <li>
                  Todos los juegos tienen garantía y han sido probados antes de
                  enviarse.
                </li>
              </ul>
            </div>
          </Col>
          <Col xs="12" lg="6">
            <Container className="h-100 ">
              <div className="auth-type">
                <div>
                  <h3>Regístrate gratis con nosotros</h3>
                  <Link to="/register">Registro</Link>
                  <p>
                    ¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link>
                  </p>
                </div>
                <div className="pt-4 pt-lg-0">
                  <h5>O, continúa como invitado</h5>
                  <button
                    onClick={() => {
                      setUser({ guest: true });
                      history.push("/cart");
                      // history.goBack();
                      ReactGA.event({
                        category: "User",
                        action: "Continued as guest",
                      });
                    }}
                  >
                    Continuar como invitado
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Guest;
