/*
  @route    GET https://gamerscode.mx/dashboard/api/webpage/featuredpurchase/All
  @desc     GET all featured games for purchase
  @access   Public
  @params   saleData
*/
export async function getFeaturedPurchaseProducts() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/webpage/featuredpurchase/All`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
