// Styles
import "./starRating.styles.scss";

export default function StarRating({ rating, setRating, hover, setHover }) {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            name="rating"
            key={index}
            className={
              index <= (hover || rating)
                ? "on star-rating-button"
                : "off star-rating-button"
            }
            onClick={() => {
              setRating(index);
              // console.log(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
}
