import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/confirmation.styles.scss";

// Assets
import waitIcon from "../assets/img/wait-icon.svg";

function Confirmation() {
  const { id } = useParams();
  return (
    <section className="confirmation__wrapper">
      <Container>
        <div className="confirmation__content">
          <img src={waitIcon} alt="Gamerscode" />
          <h4>En espera de tu pago</h4>
          <p>
            Muchas gracias por tu orden, te pedimos ir al establecimiento más
            cercano a realizar tu pago. Cuando lo efectues y lo validemos,
            prepararemos tu producto para su envío.
          </p>
          <Link to="/">Regresar</Link>
        </div>
      </Container>
    </section>
  );
}

export default Confirmation;
