import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Card from "react-bootstrap/Card";

// API
import { getFeaturedPurchaseProducts } from "../api/purchase";
import { getRetroSearchProduct } from "../api/search";

// Custom styles
import "../scss/pages/sell.styles.scss";

// Utils
import formatMoney from "../utils/formatMoney";

const useFetch = (searchQuery) => {
  const [data, setData] = useState({
    slug: "",
    results: [],
  });
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    // console.log(data.slug);
    if (data.slug !== "") {
      setLoadingSearch(true);
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            const res = await getRetroSearchProduct(`${searchQuery}`);
            setData({ ...data, results: res });
            setLoadingSearch(false);
          } catch (err) {
            setLoadingSearch(false);
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    if (data.slug === "") {
      setData({ slug: "", results: [] });
    }
  }, [data.slug]);
  console.log(data);
  return { data, setData, loadingSearch };
};

function Sell() {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { data, setData, loadingSearch } = useFetch(searchQuery);
  const search = (event) => {
    setSearchQuery(event.target.value);
    setData({ slug: event.target.value });
  };
  var items = useRef();
  let result = [];

  // const result = [];
  // items.forEach((element) => {
  //   for (let {
  //     exchangePrice,
  //     platform,
  //     productId,
  //     productName,
  //     purchasePrice,
  //   } of element) {
  //     result.push({
  //       exchangePrice,
  //       platform,
  //       productId,
  //       productName,
  //       purchasePrice,
  //     });
  //   }
  // });

  // let apis = [
  //   {info: {title: 'select some title'}},
  //   {info: {title: 'some title 2'}},
  //   {info: {title: 'some title 3'}}
  // ];

  // let toSearch = 'select some title';

  // let result = apis.filter(o=> o.info.title === toSearch);
  // console.log(searchQuery.toString());

  // let searchResults = [];

  // console.log(searchResults);
  // useEffect(() => {
  //   searchResults = allGames.filter((product) => {
  //     return Object.keys(product).some((key) =>
  //       product[key]
  //         .toString()
  //         .toLowerCase()
  //         .includes(searchQuery.toString().toLowerCase())
  //     );
  //   });
  // }, [searchQuery]);

  // console.log(searchResults);

  // let searchResults = result.map((res) => console.log(res));
  // result.map((res) => console.log(res.productName.toString().toLowerCase()));
  let searchResults = allGames?.filter((product) => {
    return Object.keys(product).some((key) =>
      product[key]
        .toString()
        .toLowerCase()
        .includes(searchQuery.toString().toLowerCase())
    );
  });

  let allArrays = [];
  if (
    searchQuery !== "" &&
    searchQuery.length > 3 &&
    data.slug != null &&
    data.results != null
  ) {
    allArrays = Object.values(data?.results);
  }

  let currentPosts =
    searchQuery !== "" && searchQuery.length > 3 && data.slug != null
      ? [].concat(...allArrays)
      : games;
  //);
  // console.log(searchResults);
  // console.log(searchResults);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getFeaturedPurchaseProducts();
      if (response !== null) {
        setGames(Object.values(response));
        console.log(Object.values(response));
        // sort((a, b) => a.firstname.localeCompare(b.firstname))
        setLoading(false);
      } else {
        setGames([]);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (games.length > 0) {
      items.current = games.map((game, index) => game);
      items.current.forEach((element) => {
        for (let {
          exchangePrice,
          platform,
          productID,
          productName,
          purchasePrice,
        } of element) {
          result.push({
            exchangePrice,
            platform,
            productID,
            productName,
            purchasePrice,
          });
        }
        setAllGames(
          result.sort((a, b) => a.productName.localeCompare(b.productName))
        );
      });
    }
  }, [games.length]);

  return (
    <section className="sell-catalogue">
      <div className="sell-catalogue__jumbotron">
        <h2>Vende tus juegos</h2>
        <p>
          Si quieres juegos/consolas que quieras vender o intercambiar a
          distancia, por favor mándanos un correo a{" "}
          <a href="mailto:vendetusjuegos@gamerscode.mx">
            vendetusjuegos@gamerscode.mx
          </a>{" "}
          para más información.
        </p>
        <p>
          También compramos juegos sin caja, revisa los precios directamente en
          tienda.
        </p>
        <p>Por favor revisa los términos y condiciones.</p>
      </div>
      <div className="sell-catalogue__container">
        <div className="sell-catalogue__search">
          <h5>Realiza una búsqueda de un juego:</h5>
          <FormControl
            type="search"
            placeholder="Escribe el nombre del juego..."
            aria-label="Search"
            value={searchQuery}
            onChange={(event) => search(event)}
            /* value={data.slug}
                              onChange={(e) => setData({ ...data, slug: e.target.value })} */
          />
        </div>
        <div className="sell-catalogue__results">
          <div className="sell-catalogue__results__container">
            {loading ? (
              <p>Cargando productos...</p>
            ) : (
              <Row xs={2} md={2} lg={4} xl={5} xxl={6}>
                {searchQuery !== "" ? (
                  // <p>wat up</p>
                  currentPosts.length > 0 ? (
                    currentPosts.map((individualGame, key) => (
                      <Col key={key}>
                        <Card
                          style={{ height: "100%" }}
                          className="product__card"
                        >
                          <Link to={`/product/${individualGame.productID}`}>
                            <Card.Img
                              variant="top"
                              src={`https://gamerscode.mx/img/products/${individualGame.productID}.jpg`}
                            />
                            <Card.Body>
                              <h5>{individualGame.productName}</h5>
                              <span>Precio de compra:</span>
                              <p className="product__card__price mb-1">
                                {formatMoney(individualGame.purchasePrice)}
                              </p>
                              <span>Precio de intercambio:</span>
                              <p className="product__card__price">
                                {formatMoney(individualGame.exchangePrice)}
                              </p>
                            </Card.Body>
                          </Link>
                        </Card>
                      </Col>
                    ))
                  ) : loadingSearch ? (
                    <Col>
                      <p>Cargando productos...</p>
                    </Col>
                  ) : loadingSearch === false || currentPosts?.length === 0 ? (
                    <Col>
                      <p>No hay productos.</p>
                    </Col>
                  ) : (
                    <Col>
                      <p>No hay productos.</p>
                    </Col>
                  )
                ) : games.length > 0 ? (
                  games?.map((game, key) => {
                    // console.log(game);
                    return (
                      <>
                        <p
                          className={`game-category ${game[0].platform
                            .split(" ")
                            .join("-")
                            .toLocaleLowerCase()}`}
                          key={key}
                        >
                          {game[0].platform}
                        </p>
                        {game.map((individualGame, key) => (
                          <Col key={key}>
                            <Card
                              style={{ height: "100%" }}
                              className="product__card"
                            >
                              <Link to={`/product/${individualGame.productID}`}>
                                <Card.Img
                                  variant="top"
                                  src={`https://gamerscode.mx/img/products/${individualGame.productID}.jpg`}
                                />
                                <Card.Body>
                                  <h5>{individualGame.productName}</h5>
                                  <span>Precio de compra:</span>
                                  <p className="product__card__price mb-1">
                                    {formatMoney(individualGame.purchasePrice)}
                                  </p>
                                  <span>Precio de intercambio:</span>
                                  <p className="product__card__price">
                                    {formatMoney(individualGame.exchangePrice)}
                                  </p>
                                </Card.Body>
                              </Link>
                            </Card>
                          </Col>
                        ))}
                      </>
                    );
                  })
                ) : (
                  <>
                    <p style={{ width: "100%" }}>
                      En este momento no hay productos.
                    </p>
                  </>
                )}
              </Row>
            )}
          </div>
        </div>
      </div>
      {/* <div className="sell-catalogue__legal">
        <h5>importante, términos y condiciones</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore
          tempora optio deserunt, facere, laborum sint odio perspiciatis,
          aliquid in veritatis exercitationem tempore praesentium ad sit.
          Dolorum rerum, sequi eos dignissimos nostrum illum ex autem
          reprehenderit sapiente deserunt vitae sunt doloremque optio earum in,
          laboriosam dicta libero pariatur. Nemo, quam quis!
        </p>
      </div> */}
    </section>
  );
}

export default Sell;
