import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/confirmation.styles.scss";

// Assets
import successIcon from "../assets/img/success-icon.svg";

function Confirmation() {
  const { id } = useParams();
  return (
    <section className="confirmation__wrapper">
      <Container>
        <div className="confirmation__content">
          <img src={successIcon} alt="Gamerscode" />
          <h4>Confirmación de compra</h4>
          <h5>
            <span>Id de orden:</span> #{id}
          </h5>
          <p>
            Muchas gracias por tu compra, en breve recibirás un correo de
            confirmación con la información de tu pedido. Por favor asegúrate de
            revisar tu bandeja de SPAM.
          </p>
          <Link to="/">Regresar</Link>
        </div>
      </Container>
    </section>
  );
}

export default Confirmation;
