import { createContext, useContext, useState, useEffect } from "react";

import { getCart, addToCart } from "../api/cart";

import { useAuth } from "./AuthContext";

export const CartContext = createContext();

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};

export function CartProvider({ children }) {
  // const user = {
  //   login: false,
  // };

  const { currentUser } = useAuth();

  const [cartItems, setCartItems] = useState([]);

  const addProductToCart = async (userID, cartItems) => {
    // console.log(cartItems);
    try {
      const response = await addToCart(userID, cartItems);
      // console.log(response);
      if (response.status === 200) {
        response.json().then((json) => {
          // setUser(json);
          // console.log(json);
          // console.log(json.length);
          // const token = json;
          if (json.length > 0) {
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
          }
        });
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const guestAddFuProductToCart = (cartItem, id) => {
    // console.log(cartItem);
    // console.log(cartItems);
    // if (cartItems !== undefined) {
    if (cartItems.length >= 1 && cartItems !== undefined) {
      // console.log("ya había");
      localStorage.setItem("cartItems", JSON.stringify(cartItem));
      cartItems.push({ ...cartItem });
    } else {
      localStorage.setItem(
        "cartItems",
        JSON.stringify([{ ...cartItem, ID_Info: id, Quantity: "1" }])
      );
    }
    localStorage.setItem("user", JSON.stringify({ guest: true }));
    // console.log(localStorage.length);
    // console.log(localStorage.getItem("cartItems"));
    // cartItems.push({ ...cartItem });
    // console.log(cartItems);
    setCartItems(JSON.parse(localStorage.getItem("cartItems")));
  };

  const guestRemoveProduct = (payload) => {
    setCartItems([
      ...cartItems.filter((item) => item.ID_Info !== payload.ID_Info),
    ]);
    localStorage.setItem(
      "cartItems",
      JSON.stringify([
        ...cartItems.filter((item) => item.ID_Info !== payload.ID_Info),
      ])
    );
    // dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const emptyUserCart = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const guestEmptyCart = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
    localStorage.removeItem("user");
    // dispatch({ type: 'REMOVE_ITEM', payload });
  };

  // const getUserCart = async (userId) => {
  //   try {
  //     const response = await getCart(userId);
  //     if (response.status === 200) {
  //       response.json().then((json) => {
  //         setCartItems(json);
  //         const token = json;
  //         if (token) {
  //           localStorage.setItem("cartItems", JSON.stringify(json));
  //         }
  //       });
  //       return response;
  //     }

  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const logout = () => {
  //   localStorage.removeItem("user");
  //   setUser({});
  // };

  // const isAuthenticated = () => {
  //   const currentUser = localStorage.getItem("user");
  //   if (!currentUser) {
  //     return {};
  //   }
  //   return JSON.parse(currentUser);
  // };

  const getTotalPriceCart = (cartItems) => {
    const amount = cartItems?.reduce(function (tally, cartItem) {
      // console.log(cartItem);
      return tally + cartItem.Quantity * cartItem.Sale;
    }, 0);
    return amount;
  };

  useEffect(() => {
    const getUserCart = async () => {
      // console.log("c0rr9+0");
      // if (Object.keys(currentUser).length !== 0) {
      if (currentUser.localId) {
        // console.log("entró");
        try {
          const response = await getCart(currentUser.localId);
          if (response.length > 0) {
            // console.log(response);
            setCartItems(response);
            // const token = response;
            localStorage.setItem("cartItems", JSON.stringify(response));
            // if (token) {
            // }
            return response;
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (currentUser.guest === true) {
        // console.log(localStorage.getItem("cartItems"));
        setCartItems(JSON.parse(localStorage.getItem("cartItems")));
      }
    };
    getUserCart();
  }, [currentUser]);

  // console.log("currentUser", currentUser);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        getTotalPriceCart,
        addProductToCart,
        guestAddFuProductToCart,
        guestRemoveProduct,
        guestEmptyCart,
        emptyUserCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
