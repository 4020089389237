import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

// Context
import { useAuth } from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Custom styles
import "../scss/pages/login.styles.scss";

// Assets
// import successIcon from "../assets/img/check-icon--green.svg";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

function Login() {
  const { loginUser } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const alert = useContext(AlertContext);

  return (
    <section className="login__wrapper">
      <div className={`overlay ${loading && "show"}`}></div>
      <Container>
        <div className="login__content">
          <h4>Iniciar sesión</h4>
          <p className="mb-3 mb-lg-0">
            Ingresa el correo electrónico y contraseña en tu registro para
            ingresar a tu perfil.
          </p>
          <Formik
            validationSchema={schema}
            onSubmit={async (values) => {
              setLoading(true);
              const response = await loginUser(values.email, values.password);
              // console.log(response);
              if (!response) {
                alert.error("Error al comunicarse con el servidor.");
                setLoading(false);
                return;
              }
              if (response.status === 200) {
                history.push("/");
                setLoading(false);
              } else {
                // console.log("error aqui bro");
                response.json().then((json) => {
                  if (json.error === "INVALID_PASSWORD") {
                    alert.error("Contraseña incorrecta.");
                  } else if (json.error === "EMAIL_NOT_FOUND") {
                    alert.error("Email no encontrado.");
                  } else if (
                    json.error ===
                    "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
                  ) {
                    alert.error(
                      "Excediste los intentos permitidos. Espera un momento o restaura tu contraseña."
                    );
                  } else {
                    alert.error(json.error);
                  }
                });
                setLoading(false);
              }
            }}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Escribe aquí tu correo"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email || touched.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Contraseña:</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password || touched.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Link to="/recover" className="d-block text-center">
                  ¿Olvidaste tu contraseña?
                </Link>
                <button type="submit">
                  {loading ? "Iniciando sesión..." : "Iniciar sesión"}
                </button>
                <p>
                  ¿No tienes cuenta? <Link to="/register">Regístrate aquí</Link>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}

export default Login;
