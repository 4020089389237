/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/sales/csi
  @desc     Check if prices haven't changed
  @access   Public
  @params   saleData
*/
export async function checkPrices(saleData) {
  const data = JSON.stringify(saleData);
  // console.log(
  //   JSON.stringify([{ ...product, ID_Info: productId, Quantity: quantity }])
  // );
  // console.log(data);
  console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/sales/csi`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST https://gamerscode.mx/dashboard/api/webstore/users/sales/
  @desc     Post an order with payment using Stripe
  @access   Public
  @params   userID
*/
export async function postSale(saleData) {
  const data = JSON.stringify(saleData);
  // console.log(
  //   JSON.stringify([{ ...product, ID_Info: productId, Quantity: quantity }])
  // );
  // console.log(data);
  console.log(data);
  try {
    const url = `${process.env.REACT_APP_API_URL}/webstore/users/sales`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, params);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}
