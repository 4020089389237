export default function formatMoney(amount = 0) {
  const options = {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  };

  // check if it's a clean currency amount
  if (amount % 100 === 0) {
    options.minimumFractionDigits = 2;
  }
  const formatter = Intl.NumberFormat("en-US", options);

  return formatter.format(amount);
}
