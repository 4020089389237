import React, { useState, useEffect, useRef, Fragment } from "react";

// Context
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const SessionTimeout = () => {
  // const { user } = useAuth();

  // const [events, setEvents] = useState(["click", "load", "scroll"]);
  // const [second, setSecond] = useState(0);

  // let timeStamp;
  // let warningInactiveInterval = useRef();
  // let startTimerInterval = useRef();

  // // start inactive check
  // let timeChecker = () => {
  //   startTimerInterval.current = setTimeout(() => {
  //     let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
  //     warningInactive(storedTimeStamp);
  //   }, 60000);
  // };

  // // warning timer
  // let warningInactive = (timeString) => {
  //   clearTimeout(startTimerInterval.current);

  //   warningInactiveInterval.current = setInterval(() => {
  //     const maxTime = 2;
  //     const popTime = 1;

  //     const diff = moment.duration(moment().diff(moment(timeString)));
  //     const minPast = diff.minutes();
  //     const leftSecond = 60 - diff.seconds();

  //     if (minPast === popTime) {
  //       setSecond(leftSecond);
  //       // setOpen(true);
  //     }

  //     if (minPast === maxTime) {
  //       clearInterval(warningInactiveInterval.current);
  //       // setOpen(false);
  //       sessionStorage.removeItem("lastTimeStamp");
  //       // logout();
  //     }
  //   }, 1000);
  // };

  // // reset interval timer
  // let resetTimer = useCallback(() => {
  //   clearTimeout(startTimerInterval.current);
  //   clearInterval(warningInactiveInterval.current);

  //   if (Object.keys(user).length === 0) {
  //     clearInterval(warningInactiveInterval.current);
  //     sessionStorage.removeItem("lastTimeStamp");
  //   } else {
  //     timeStamp = moment();
  //     sessionStorage.setItem("lastTimeStamp", timeStamp);
  //   }
  //   timeChecker();
  //   // setOpen(false);
  // }, [user]);

  // if (Object.keys(user).length !== 0) {
  //   console.log(" usuario");
  // }

  // // handle close popup
  // const handleClose = () => {
  //   // setOpen(false);

  //   resetTimer();
  // };

  // useEffect(() => {
  //   events.forEach((event) => {
  //     window.addEventListener(event, resetTimer);
  //   });

  //   timeChecker();

  //   return () => {
  //     clearTimeout(startTimerInterval.current);
  //     //   resetTimer();
  //   };
  // }, [resetTimer, events, timeChecker]);
  // return <Fragment />;
  const { logout } = useAuth();

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [timerId, setTimerId] = useState(false);
  const renderCount = useRef(1);

  const history = useHistory();

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === "hidden") {
        // set timer to log user out
        const id = window.setTimeout(() => {
          setIsLoggedOut(true);
          logout();
          history.push("/");
        }, 3600 * 1000);
        setTimerId(id);
      } else {
        // clear existing timer
        window.clearTimeout(timerId);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => document.removeEventListener("visibilitychange", autoLogout);
  }, [timerId]);
  return <Fragment />;
};

export default SessionTimeout;
