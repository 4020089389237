import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/confirmation.styles.scss";

// Assets
import errorIcon from "../assets/img/error-icon--red.svg";

function Confirmation() {
  const { id } = useParams();
  return (
    <section className="confirmation__wrapper">
      <Container>
        <div className="confirmation__content">
          <img
            src={errorIcon}
            className="confirmation-error"
            alt="Gamerscode"
          />
          <h4>Tu orden no pudo ser procesada</h4>
          <p>
            Hubo un problema al establecer un método de pago válido. Por favor
            inténtalo de nuevo.
          </p>
          <Link to="/">Regresar</Link>
        </div>
      </Container>
    </section>
  );
}

export default Confirmation;
