import React from "react";

import Pagination from "react-bootstrap/Pagination";

import "./pagination.styles.scss";

function CustomPagination({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  setImageShow,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const resetSize = () => {
    if (setImageShow !== undefined) {
      let images = document.querySelectorAll(".prodcut__card-retro-img");
      setImageShow(false);
      return Array.from(images).map((elem) => {
        elem.style.transform = "unset";
      });
    } else {
      return;
    }
  };
  // console.log(pageNumbers);
  return (
    <Pagination>
      {/* <Pagination.First
        onClick={() => {
          paginate(1);
          window.scrollTo(0, 0);
        }}
      /> */}
      <Pagination.Prev
        onClick={() => {
          paginate(currentPage - 1);
          window.scrollTo(0, 0);
          resetSize();
        }}
        disabled={currentPage === 1}
      />
      {/* {totalPosts <= postsPerPage && (
        <Pagination.Item className={totalPosts <= postsPerPage ? "active" : ""}>
          1
        </Pagination.Item>
      )} */}

      {window.innerWidth < 720 ? (
        <>
          {pageNumbers.slice(0, 5).map((number, key) => (
            <Pagination.Item
              onClick={() => {
                paginate(number);
                window.scrollTo(0, 0);
                resetSize();
              }}
              className={currentPage === number ? "active" : ""}
              key={key}
            >
              {number}
            </Pagination.Item>
          ))}
          {pageNumbers.length >= 5 && (
            <>
              {currentPage > 5 && currentPage < pageNumbers.length && (
                <Pagination.Item className="active">
                  {currentPage}
                </Pagination.Item>
              )}
              <Pagination.Ellipsis />{" "}
              <Pagination.Item
                onClick={() => {
                  paginate(pageNumbers.length);
                  window.scrollTo(0, 0);
                  resetSize();
                }}
                className={currentPage === pageNumbers.length ? "active" : ""}
              >
                {pageNumbers.length}
              </Pagination.Item>
            </>
          )}
        </>
      ) : (
        <>
          {pageNumbers.slice(0, 15).map((number, key) => (
            <Pagination.Item
              onClick={() => {
                paginate(number);
                window.scrollTo(0, 0);
                resetSize();
              }}
              className={currentPage === number ? "active" : ""}
              key={key}
            >
              {number}
            </Pagination.Item>
          ))}
          {pageNumbers.length >= 15 && (
            <>
              {currentPage > 15 && currentPage < pageNumbers.length && (
                <Pagination.Item className="active">
                  {currentPage}
                </Pagination.Item>
              )}
              <Pagination.Ellipsis />{" "}
              <Pagination.Item
                onClick={() => {
                  paginate(pageNumbers.length);
                  window.scrollTo(0, 0);
                  resetSize();
                }}
                className={currentPage === pageNumbers.length ? "active" : ""}
              >
                {pageNumbers.length}
              </Pagination.Item>
            </>
          )}
        </>
      )}

      {/* {currentPage > 5 && currentPage < pageNumbers.length && (
        <Pagination.Item className="active">{currentPage}</Pagination.Item>
      )} */}

      {/* {pageNumbers.map((number, key) => (
        <Pagination.Item
          onClick={() => {
            paginate(number);
            window.scrollTo(0, 0);
          }}
          className={currentPage === number ? "active" : ""}
          key={key}
        >
          {number}
        </Pagination.Item>
      ))} */}

      <Pagination.Next
        onClick={() => {
          paginate(currentPage + 1);
          window.scrollTo(0, 0);
          resetSize();
        }}
        disabled={currentPage === pageNumbers.length}
      />
      {/* <Pagination.Last
        onClick={() => {
          paginate(pageNumbers.at(-1));
          window.scrollTo(0, 0);
        }}
      /> */}
    </Pagination>
  );
}

export default CustomPagination;
