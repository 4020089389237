import React from "react";

function Cookies() {
  return (
    <section className="legal">
      <div className="legal__header">
        <h2>Política de cookies 🍪</h2>
      </div>
      <div className="legal__content">
        <p>
          El usuario conoce y acepta que la empresa Gamers Code podrá utilizar
          un sistema de seguimiento mediante la utilización de Cookies; las
          cuales son pequeños archivos que se instalan, con una duración
          limitada que ayudan a personalizar los servicios. Las Cookies se
          utilizan con el fin de conocer los intereses, el comportamiento y la
          demografía de quienes visitan o sus usuarios de nuestro Sitio Web y de
          esa forma, comprender mejor sus necesidades e intereses y darles un
          mejor servicio o proveerle información relacionada. También usaremos
          la información obtenida por intermedio de las Cookies para analizar
          las páginas navegadas por el visitante o usuario, las búsquedas
          realizadas, mejorar nuestras iniciativas comerciales y promocionales,
          mostrar publicidad o promociones, perfeccionar nuestra oferta de
          artículos, personalizar contenidos, presentación y servicios; también
          podremos utilizar Cookies para promover y hacer cumplir las reglas y
          seguridad del sitio.
        </p>
        <p className="my-2">
          La instalación, permanencia y existencia de las Cookies en el
          computador del usuario depende de su exclusiva voluntad y puede ser
          eliminada cuando el usuario así lo desee.{" "}
        </p>
        <p className="mb-2">
          Así mismo, se pueden encontrar Cookies instalados por terceros en
          nuestro sitio Web. Cabe aclarar que estas políticas cubren la
          utilización de Cookies por este sitio y no la utilización de Cookies
          por parte de anunciantes. Nosotros no controlamos el uso de Cookies
          por terceros.
        </p>
        <h5 className="mb-2">Cambiar preferencias sobre las Cookies:</h5>
        <h6>Internet Explorer:</h6>
        <ul>
          <li>Abre Internet Explorer.</li>
          <li>Selecciona "Herramientas" y después "Opciones de Internet".</li>
          <li>Selecciona la pestaña Privacidad.</li>
          <li>
            En la sección "Configuración", en "Sitios", puedes bloquear o
            permitir las cookies de un sitio web en particular solo con agregar
            la dirección del sitio web a la lista.
          </li>
          <li>
            En la pestaña "Avanzadas" puedes configurar como se van a utilizar
            las cookies cuando navegues.
          </li>
          <li>Selecciona "Aceptar". Ya puedes cerrar la ventana.</li>
        </ul>
        <h6 className="mb-2">Google Chrome:</h6>
        <ul>
          <li>Abre Google Chrome.</li>
          <li>Haz clic en "Configuración".</li>
          <li>
            Aparece una página de menú, haz clic en "Configuración avanzada" y
            selecciona "Privacidad y seguridad".
          </li>
          <li>
            Haz clic en "Configuración del sitio" y luego en "Datos de sitios y
            cookies" puedes configurar como se van a utilizar las cookies cuando
            navegues con Chrome.
          </li>
        </ul>
        <h6 className="mb-2">Mozilla Firefox:</h6>
        <ul>
          <li>Abre Firefox.</li>
          <li>
            Haz clic arriba en el botón de la derecha de Firefox "Abrir menú" y
            selecciona "Opciones".
          </li>
          <li>Haz clic en "Privacidad y seguridad".</li>
          <li>
            Selecciona uno de los tres niveles: "Estándar", "Estricto" o
            "Personalizado".
          </li>
          <li>
            También puedes seleccionar una opción para eliminar las cookies:
            "Eliminar cookies y datos del sitio cuando cierre Firefox".
          </li>
          <li>
            Haz clic en "Aceptar" una vez seleccionada la configuración. Ya
            puedes cerrar la ventana.
          </li>
        </ul>
        <h6 className="mb-2">Safari:</h6>
        <ul>
          <li>Abre Safari.</li>
          <li>Selecciona "Preferencias"; y haz clic en “Privacidad”.</li>
          <li>
            A continuación, selecciona la configuración "Impedir seguimiento
            entre sitios" y "Cookies y datos de sitios web".
          </li>
        </ul>
        <h6 className="mb-2">Otros navegadores:</h6>
        <ul>
          <li>
            Para configurar las cookies en otros navegadores se puede obtener
            información a través de la sección de ayuda o asistencia de dicho
            navegador.
          </li>
        </ul>
        <h5 className="mb-2">Tipos de Cookies:</h5>
        <p>Exceptuadas:</p>
        <ul>
          <li>Cookies de entrada de usuario.</li>
          <li>
            Cookies de identificación o autenticación del usuario. (únicamente
            de sesión).
          </li>
          <li>Cookies de seguridad del usuario.</li>
          <li>Cookies de reproductor multimedia.</li>
          <li>Cookies de sesión para equilibrar la carga.</li>
          <li>Cookies de personalización de la interfaz de usuario.</li>
          <li>
            Cookies de complemento (plug-in) para intercambiar contenidos
            sociales
          </li>
        </ul>
        <p className="mt-2">No exceptuadas:</p>
        <ul>
          <li>El resto de cookies.</li>
        </ul>
        <p className="mt-2">Cookies según entidad que las gestione:</p>
        <ul>
          <li>
            Cookies propias: Son aquellas que se envían al equipo terminal del
            usuario desde un equipo o dominio gestionado por el propio editor y
            desde el que se presta el servicios solicitado por el usuario.
          </li>
          <li>
            Cookies de tercero: Son aquellas que se envían al equipo terminal
            del usuario desde un equipo o dominio que no es gestionado por el
            editor, sino por otra entidad que trata los datos obtenidos a través
            de las cookies.
          </li>
        </ul>
        <p className="mt-2">
          Cookies según el tiempo que permanecen activadas:
        </p>
        <ul>
          <li>
            Cookies de sesión: Son un tipo de cookies diseñadas para recabar y
            almacenar datos mientras el usuario accede a una página web.
          </li>
          <li>
            Cookies persistentes: Son un tipo de cookies en el que los datos
            siguen almacenados en el terminal y pueden ser accedidos y tratados
            durante un período definido por el responsable de la cookie y que
            puede ir de unos minutos a varios años.
          </li>
        </ul>
        <p className="mt-2">Cookies según finalidad:</p>
        <ul>
          <li>
            Cookies de análisis: Son aquellas que, bien tratadas por el titular
            del sitio, o por terceros, permiten cuantificar el número de
            usuarios y así realizar la medición y análisis estadístico de la
            utilización, que hacen los usuarios del servicio ofertado. Para
            ello, se analiza su navegación en la página web, con el fin de
            mejorar la oferta de productos o servicios que le ofrecemos.
          </li>
          <li>
            Cookies publicitarias: Son aquellas que permiten la gestión, de la
            forma más eficaz posible, de los espacios publicitarios que, en su
            caso, el editor haya incluido en una página web, aplicación o
            plataforma desde la que presta el servicio solicitado en base a
            criterios como el contenido editado o la frecuencia en la que se
            muestran los anuncios.
          </li>
          <li>
            Cookies sociales son necesarias para las redes sociales externas
            (Facebook, Google, Twitter, Pinterest, Instagram, etc…). Su función
            es controlar la interacción con los widgets sociales dentro de la
            página.
          </li>
          <li>
            Cookies de publicidad comportamental: Son aquellas que permiten la
            gestión, de la forma más eficaz posible, de los espacios
            publicitarios que, en su caso, el editor haya incluido en una página
            web, aplicación o plataforma desde la que presta el servicio
            solicitado. Estas cookies almacenan información del comportamiento
            de usuarios obtenida a través de la observación continuada de sus
            hábitos de navegación, lo que permite desarrollar un perfil
            específico para mostrar publicidad en función del mismo.
          </li>
          <li>
            También es posible que al visitar alguna página web o al abrir algún
            email donde se publique algún anuncio o alguna promoción sobre
            determinados productos o servicios se instale en el navegador alguna
            cookie que se utiliza para mostrar posteriormente publicidad
            relacionada con la búsqueda que se haya realizado, para desarrollar
            un control de los anuncios en relación, por ejemplo, con el número
            de veces que son vistos, donde aparecen, a qué hora se ven, etc.
          </li>
        </ul>
        <p>
          Esta política puede ser adaptada en cualquier momento, debido a
          actualizaciones en el sitio web o cambios en las directrices sobre el
          uso de cookies. Nos reservamos el derecho a adaptar los contenidos
          sobre el uso de cookies y adaptar las incluidas en el listado en
          cualquier momento y sin previo aviso. En el caso de estar desacuerdo
          con la directriz revisada, puede modificar sus ajustes personalizados.
        </p>
      </div>
    </section>
  );
}

export default Cookies;
